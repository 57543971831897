import React, {createContext, useCallback, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';

import {BANKS_KEY, BANKS_DEFAULT_VALUE} from './state';
import settingsService from '../services/settings';

const banks = (useStorage) => {
  const BanksContext = createContext({
    banks: BANKS_DEFAULT_VALUE,
    updateBanks: () => {},
    banksIsReady: false,
  });
  const {Provider} = BanksContext;

  const BanksProvider = (props) => {
    const [banks, updateBanks, hydrated] = useStorage(
      BANKS_KEY,
      BANKS_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        banks,
        updateBanks,
        banksIsReady: hydrated,
      }),
      [banks, updateBanks, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  BanksProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useBanks = () => {
    const context = useContext(BanksContext);

    if (!context) {
      throw new Error('useBanks must be used within a BanksProvider');
    }

    const {banks, updateBanks: update, ...rest} = context;

    const getProfileBanks = useCallback(
      async (user) => {
        const {banksProfile} = banks;
        if (banksProfile.banks.length === 0) {
          try {
            const response = await settingsService.getCountryBanks(
              user.headers,
              user.iso_code_country,
            );
            const banks_response = [];
            response.data.forEach((bank) => {
              const {
                id,
                attributes: {account_types_codes, name},
              } = bank;

              banks_response.push({
                value: id,
                label: name,
                account_types: account_types_codes,
              });
            });

            update({
              banksProfile: {
                banks: banks_response,
              },
            });
          } catch (error) {}
        } else {
          return banksProfile.banks;
        }
      },
      [banks, update],
    );

    const banksClean = () => {
      update(BANKS_DEFAULT_VALUE);
    };

    return {
      ...rest,
      banks,
      getProfileBanks,
      banksClean,
    };
  };

  return {
    BanksProvider,
    useBanks,
  };
};

export default banks;
