import React, {createContext, useCallback, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';

import {BANNERS_KEY, BANNERS_DEFAULT_VALUE} from './state';

const banners = (useStorage) => {
  const BannersContext = createContext({
    banners: BANNERS_DEFAULT_VALUE,
    updateBanners: () => {},
    bannersIsReady: false,
  });
  const {Provider} = BannersContext;

  const BannersProvider = (props) => {
    const [banners, updateBanners, hydrated] = useStorage(
      BANNERS_KEY,
      BANNERS_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        banners,
        updateBanners,
        bannersIsReady: hydrated,
      }),
      [banners, updateBanners, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  BannersProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useBanners = () => {
    const context = useContext(BannersContext);

    if (!context) {
      throw new Error('useBanners must be used within a BannersProvider');
    }

    const {banners, updateBanners: update, ...rest} = context;

    const hiddenReferralNotification = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          referrals: false,
        },
      });
    }, [banners, update]);

    const hiddenProfileVerificationNotification = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          profile_verification: false,
        },
      });
    }, [banners, update]);

    const hiddenProfileVerificationPopUp = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          profile_verification_popup: false,
        },
      });
    }, [banners, update]);

    const hiddenEmailConfirmedNotification = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          email_confirmed: false,
        },
      });
    }, [banners, update]);

    const hiddenSessionExpiredNotification = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          sessionExpired: false,
        },
      });
    }, [banners, update]);

    const showEmailConfirmedNotification = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          email_confirmed: true,
        },
      });
    }, [banners, update]);

    const hiddenWiffiError = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          isWiffiError: false,
        },
      });
    }, [banners, update]);

    const bannersClean = () => {
      update(BANNERS_DEFAULT_VALUE);
    };

    const hiddenNotificationCoupon = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          coupon: false,
        },
      });
    }, [banners, update]);

    const showNotificationCoupon = useCallback((name) => {
      update({
        banners: {
          ...banners.banners,
          coupon: true,
          couponMoment:name
        },
      });
    }, [banners, update]);

    const hiddenGeneralNotification = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          general: false,
        },
      });
    }, [banners, update]);

    const resetNotificationCoupon = useCallback(() => {
      update({
        banners: {
          ...banners.banners,
          coupon: false,
          couponMoment: '',
        },
      });
    }, [banners, update]);

    const updateBannersUsers = useCallback((bannerUser) => {
      update({
        banners: {
          ...banners.banners,
          bannerUser,
        },
      });
    }, [banners, update]);

    const showBannersUsers = useCallback(() => {
      return banners.banners.bannerUser;
    }, [banners]);

    return {
      ...rest,
      banners,
      hiddenReferralNotification,
      hiddenProfileVerificationNotification,
      hiddenProfileVerificationPopUp,
      hiddenEmailConfirmedNotification,
      showEmailConfirmedNotification,
      hiddenSessionExpiredNotification,
      hiddenWiffiError,
      bannersClean,
      hiddenNotificationCoupon,
      resetNotificationCoupon,
      showNotificationCoupon,
      hiddenGeneralNotification,
      updateBannersUsers,
      showBannersUsers,
    };
  };

  return {
    BannersProvider,
    useBanners,
  };
};

export default banners;
