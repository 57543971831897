import { apiCall } from '../../middlewares';
import { ENDPOINTS } from '../../config/constants';

const sendRequestVitaCard = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.requestVitaCard,
    headers,
    data,
  });
};

const sendRenowalVitaCard = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.renowalVitaCard,
    headers,
    data,
  });
};

const activateCard = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.activateCard,
    headers,
    data,
  });
};

const setCardPin = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.createPin,
    headers,
    data,
  });
};

const showVirtualCard = (headers, card_id) => {
  return apiCall({
    ...ENDPOINTS.vita_card.showVirtualCard(card_id),
    headers
  });
};

const changeCardStatus = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.changeCardStatus,
    data,
    headers
  });
};

const getCardData = (headers) => {
  return apiCall({
    ...ENDPOINTS.vita_card.getCardData,
    headers
  });
};

const changeVitaCardCurrency = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.changeVitaCardCurrency,
    data,
    headers
  });
};

const tokenizationAddGPay = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.addGPay,
    data,
    headers
  });
};

const tokenizationAddApplyPay = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.vita_card.addGPay,
    data,
    headers
  });
};

export default {
  showVirtualCard,
  sendRequestVitaCard,
  activateCard,
  setCardPin,
  changeCardStatus,
  getCardData,
  changeVitaCardCurrency,
  sendRenowalVitaCard,
  tokenizationAddGPay,
  tokenizationAddApplyPay,
};