import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import Share from 'react-native-share';
// import * as yup from 'yup';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
// import { Alert } from 'react-native';
import {
  SETTINGS_DEFAULT_VALUE,
  SETTINGS_KEY,
} from 'dumdee/state';

import { Colors } from '../resources';
import { width, height } from '../hooks/useWindowsSize';


export const networkProps = {
  pingServerUrl: 'https://www.vitawallet.io/',
  pingInterval: 30000,
  pingOnlyIfOffline: true,
};

export const showAlert = (error) => { alert(error) };

export const isValidateIp = (ip) => {
  return (
    ip.match(/^(?=.*[^.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).?){4}/g) !==
    null
  );
};

// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

const scale = (size) => (width / guidelineBaseWidth) * size;
const verticalScale = (size) => (height / guidelineBaseHeight) * size;

/* eslint-disable no-console */
const log = (name, data) => {
  // console.group(name);
  if (data) {
    // console.log(data);
  }
  // console.groupEnd();
};
/* eslint-enable no-console */

export {
  log,
  scale,
  verticalScale,
};

// export const prepareHeaders = (
//   params = {
//     token: '',
//     client_id: '',
//     uid: '',
//     'access-token-mobile': '',
//   },
// ) => {
//   const {client_id, uid, token} = params;
//   if (params['access-token-mobile']) {
//     return {
//       'access-token-mobile': params['access-token-mobile'],
//       client: '',
//       uid,
//     };
//   } else {
//     return {
//       'access-token': token,
//       client: client_id,
//       uid,
//     };
//   }
// };

export const isActiveMoney = (category) => {
  return (
    category === 'deposit' ||
    category === 'received' ||
    category === 'exchange' ||
    category === 'payment'
  );
};

export const prepareUser = (response) => {
  const user = response.data;
  let headers = response.headers;
  /*
  if (headers.hasOwnProperty('expiry')) {
    headers.expiry = moment().add(1, 'minutes').unix().toString();
  }

  if (headers.hasOwnProperty('expire-token-mobile')) {
    headers['expire-token-mobile'] = moment()
      .add(1, 'minutes')
      .unix()
      .toString();
  }
  */
  return {
    ...user,
    headers,
  };
};

export const getTimeDifference = (expiryTime, format) => {
  const timeLeft = moment.unix(expiryTime).diff(moment().toDate().getTime());
  return moment(timeLeft).format(format);
};

export const IOPeripherals = {
  QRSelect: 'IOPeripherals/QRSelect',
  CountrySelect: 'IOPeripherals/CountrySelect',
  CurrencySelect: 'IOPeripherals/CurrencySelect',
  NumberKeyboard: 'IOPeripherals/NumberKeyboard',
};

// export const isUserExpirySession = (user, minutes = 0) => {
//   const now = moment().toDate().getTime();
//   const expiryTime = moment
//     .unix(
//       user.headers['expire-token-mobile']
//         ? user.headers['expire-token-mobile']
//         : user.headers.expiry,
//     )
//     .subtract(minutes, 'minute')
//     .toDate()
//     .getTime();
//   return expiryTime < now;
// };

export const isoCodeCountries = {
  Argentina: 'ar',
  Bolivia: 'bo',
  Chile: 'cl',
  Brasil: 'br',
  Colombia: 'co',
  Ecuador: 'ec',
  México: 'mx',
  Panamá: 'pa',
  Paraguay: 'py',
  Perú: 'pe',
  'Perú (USD)': 'peusd',
  Uruguay: 'uy',
  Venezuela: 've',
  'Estados Unidos': 'us',
  'Estados Unidos - WIRE': 'uswires',
  'Reino Unido': 'gb',
  'China': 'cn',
  'Costa Rica': 'cr',
  'República Dominicana': 'do',
  'Haití': 'ht',
};

export const isoCodeCountriesCurrencies = {
  ar: 'ARS',
  bo: 'BOB',
  cl: 'CLP',
  bra: 'BRL',
  co: 'COP',
  ec: 'USD',
  mx: 'MXN',
  pa: 'PAB',
  py: 'PYG',
  pe: 'PEN',
  peusd: 'USD',
  uy: 'UYU',
  ve: 'VES',
  us: 'USD',
  uswires: 'USD',
  gb: 'GBP',
  cn: 'CNY',
  cr: 'CRC',
  do: 'DOP',
  ht: 'USD',
};

export const currencyCountries = {
  Argentina: 'ARS',
  Bolivia: 'BOB',
  Chile: 'CLP',
  Brasil: 'BRL',
  Colombia: 'COP',
  Ecuador: 'USD',
  México: 'MXN',
  Panamá: 'PAB',
  Paraguay: 'PYG',
  Perú: 'PEN',
  'Perú (USD)': 'USD',
  Uruguay: 'UYU',
  Venezuela: 'VES',
  'Estados Unidos': 'USD',
  'Estados Unidos - WIRE': 'USD',
  'Reino Unido': 'GBP',
  'China': 'CNY',
  'Costa Rica': 'CRC',
  'República Dominicana': 'DOP',
  'Haití': 'USD',
};

export const regEx = {
  allPoints: /\./g,
  chileanRut: /^0*(\d{1,3}(\.\d{3})*)-?([\dkK])$/,
  dateDDMMYYYY: /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  btcWallet: /(?:[a-zA-Z1-9]{24,70})/g,
  btcWalletFromVitaWithinAmount: /bitcoin:(?:[a-zA-Z1-9]{24,70})\?amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])(([0-9][0-9][0-9])*)(\.([0-9]*))/g,
  btcWalletFromVita: /bitcoin:(?:[a-zA-Z1-9]{24,70})/g,
  btcWalletFromVitaWithinAmountAndDescription: /bitcoin:(?:[a-zA-Z1-9]{24,70})\?amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])(([0-9][0-9][0-9])*)(\.([0-9]*))\?description=((.)*)/g,
  btcWalletFromVitaWithinDescription: /bitcoin:(?:[a-zA-Z1-9]{24,70})\?description=((.)*)/g,
  fiatAddressFromVita: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))/g,
  fiatAddressFromVitaWithinAmountAndDescription: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))&amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])((\.([0-9][0-9][0-9]))*)(,([0-9]{2}))&description=((.)*)/g,
  fiatAddressFromVitaWithinAmount: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))&amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])((\.([0-9][0-9][0-9]))*)(,([0-9]{2}))/g,
  fiatAddressFromVitaWithinDescription: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))&description=((.)*)/g,
};

export const rutMask = (value = '', country = '', document_type_key = '') => {
  if (
    normalize(country).toLowerCase() === 'chile' &&
    document_type_key !== 'passport'
  ) {
    value = value.replace(/\./g, '').replace('-', '');

    if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
      value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
      value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, '$1.$2.$3-$4');
    } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
      value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, '$1.$2.$3');
    } else if (value.match(/^(\d)(\d{0,2})$/)) {
      value = value.replace(/^(\d)(\d{0,2})$/, '$1.$2');
    }

    if (
      value.length > 0 &&
      (value[value.length - 1] === '.' || value[value.length - 1] === '-')
    ) {
      value = value.slice(0, value.length - 1);
    }
  }

  return value;
};

export const formatRut = (rut) => {
  const newRut = rut.replace(/\./g, '').replace(/-/g, '').trim().toLowerCase();
  const lastDigit = newRut.substr(-1, 1);
  const rutDigit = newRut.substr(0, newRut.length - 1)
  let format = '';
  for (let i = rutDigit.length; i > 0; i--) {
    const e = rutDigit.charAt(i - 1);
    format = e.concat(format);
    if (i % 3 === 0) {
      format = '.'.concat(format);
    }
  }
  return format.concat('-').concat(lastDigit);
}

// export const documentsYup = {
//   cl: yup
//     .string()
//     .matches(
//       // /^0*(\d{1,3}(\.\d{3})*)\-?([\dkK])$/,
//       /^[0-9]+[\dkK]$/,
//       'Ingrese sin puntos y guiones',
//     )
//     .min(5, 'Ingrese sin puntos y guiones'),
//   /*.test(
//       'document_number',
//       'Formato inválido, ejemplo: 11.111.111-1/11.111.111-K',
//       (value) => {
//         return value.length > 3
//           ? /^0*(\d{1,3}(\.\d{3})*)\-?([\dkK])$/.test(value)
//           : false;
//       },
//     ),*/
//   mx: yup
//     .string()
//     .matches(/^[0-9]+$/, 'Solo números')
//     .min(6, 'Mínimo 6 digitos')
//     .max(22, 'Máximo 22 digitos'),
//   all: yup
//     .string()
//     .matches(/^[0-9]+$/, 'Solo números')
//     .min(6, 'Mínimo 6 digitos')
//     .max(15, 'Máximo 15 digitos'),
//   allCompanyId: yup
//     .string()
//     .min(6, 'Mínimo 6 digitos')
//     .max(15, 'Máximo 15 digitos'),
//   allDni: yup.string().min(6, 'Mínimo 6 digitos').max(15, 'Máximo 15 digitos'),
//   passport: yup
//     .string()
//     .min(6, 'Mínimo 6 digitos')
//     .max(11, 'Máximo 11 digitos'),
// };

export const isValidEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
};

export const isValidBtcWallet = (wallet) => {
  return /(?:[a-zA-Z1-9]{24,70})/g.test(wallet);
};

export const isValidDateDDMMYYYY = (date) => {
  return /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(
    date,
  );
};

export const share = async (
  value,
  title = 'Comparte desde tus redes sociales',
) => {
  try {
    /*await Share.open({
      message: value,
      title,
    });*/
  } catch (error) { }
};

export const videoConstraints = {
  width: 1280,
  height: 720,
};

export const addPushNotificationFromCache = (addPushNotification) => {
  // eslint-disable-next-line no-undef
  if (window && window.caches) {
    caches.has('fcm-1').then(function (exist) {
      if (exist) {
        // eslint-disable-next-line no-undef
        caches.open('fcm-1').then(function (cache) {
          cache.match('/fcm').then((settings) => {
            if (settings && settings.body) {
              const reader = settings.body.getReader();
              reader.read().then(function processText({ done, value }) {
                // eslint-disable-next-line no-undef
                const notifications = JSON.parse(
                  // eslint-disable-next-line no-undef
                  new TextDecoder('utf-8').decode(value),
                );
                notifications.forEach((notification) => {
                  addPushNotification(notification);
                });
              });
            }
            cache.delete('/fcm');
          });
          // eslint-disable-next-line no-undef
          caches.delete('fcm-1');
        });
      }
    });
  }
};

export const addPushNotification = async (remoteMessage) => {
  try {
    const settings =
      JSON.parse(await AsyncStorage.getItem(SETTINGS_KEY)) ||
      SETTINGS_DEFAULT_VALUE;

    if (remoteMessage.hasOwnProperty('data')) {
      const data = remoteMessage.data.hasOwnProperty('object_type')
        ? JSON.parse(remoteMessage.data.object_data)
        : remoteMessage.data;

      const notification = {
        ...data,
        notification_type: remoteMessage.data.hasOwnProperty('object_type')
          ? remoteMessage.data.object_type
          : 'promotion',
        sentTime: remoteMessage.sentTime,
      };

      await AsyncStorage.setItem(
        SETTINGS_KEY,
        JSON.stringify({
          ...settings,
          pushNotifications: [
            ...settings.pushNotifications,
            { ...notification, isRead: false },
          ],
        }),
      );
    }
  } catch (error) { }
};

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

export const capitalizeFullName = (fullName) => {
  fullName = fullName.toLowerCase();
  const tokens = fullName.split(' ');
  const first = tokens[0][0].toUpperCase() + tokens[0].slice(1);
  const second = tokens[1]
    ? tokens[1][0].toUpperCase() + tokens[1].slice(1)
    : '';
  const newName = `${first} ${second}`;
  return newName.trim();
};

export const onlyTwoWordsFromFullName = (firstName, lastName) => {
  firstName = firstName.toLowerCase();
  lastName = lastName.toLowerCase();
  firstName = firstName.split(' ')[0];
  lastName = lastName.split(' ')[0];
  firstName = firstName[0].toUpperCase() + firstName.slice(1);
  lastName = lastName[0].toUpperCase() + lastName.slice(1);
  return `${firstName} ${lastName}`;
};

export const toWithBottomBar = async () => {
  try {
    await changeNavigationBarColor(Colors.white, true);
  } catch (e) { }
};

export const toDarkBottomBar = async () => {
  try {
    await changeNavigationBarColor(Colors.gray_dark);
  } catch (e) { }
};

export const normalize = (function () {
  var from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç',
    to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc',
    mapping = {};

  for (var i = 0, j = from.length; i < j; i++) {
    mapping[from.charAt(i)] = to.charAt(i);
  }

  return function (str) {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) {
        ret.push(mapping[c]);
      } else {
        ret.push(c);
      }
    }
    return ret.join('');
  };
})();

export const titleNavigationManager = (scene) => {
  switch (scene) {
    case 'Integration': {
      return 'Vita Business';
    }
    case 'Exchange': {
      return 'Crypto';
    }
    case 'Receive': {
      return 'Recibir';
    }
    case 'Recharge': {
      return 'Recarga Vita Wallet';
    }
    case 'AccountBank': {
      return 'Tus cuentas bancarias';
    }
    case 'PersonalInformation': {
      return 'Tu información personal';
    }
    case 'VerificationStatus': {
      return 'Estado de verificación';
    }
    case 'security': {
      return 'Seguridad';
    }
    case 'PushNotifications': {
      return 'Notificaciones';
    }
    case 'CryptoSettings': {
      return 'Configuración Crypto';
    }
    case 'Sent': {
      return 'Envía dinero';
    }
    case 'AccountActivator': {
      return 'Verificar cuenta';
    }
    default: {
      return 'Vita Wallet';
    }
  }
};
