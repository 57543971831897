import { Platform } from "react-native";

import userPlus from './assets/user_plus.png';
import userPlusOff from './assets/user_plus_off.png';
import defaultPhoto from './assets/default_photo.png';
import arrowRight from './assets/arrow_right.png';
import close from './assets/close.png';
import closeBlue from './assets/close_blue.png';
import closeWhite from './assets/close_white.png';
import elbaDice from './assets/elba_dice.png';
import userX from './assets/user_x.png';
import userCheck from './assets/user_check.png';
import checkDark from './assets/check_dark.png';
import checkBlue from './assets/check_blue.png';
import checkWhite from './assets/check_white.png';
import vitaLogo from './assets/vita_logo.png';
import vitaLogoGray from './assets/vita_logo_gray.png';
import worm from './assets/worm.png';
import check from './assets/check.png';
import checkOrange from './assets/check_orange.png';
import xCheck from './assets/x_check.png';
import uncheck from './assets/uncheck.png';
import couponIcon from './assets/tiquetCoupon.png';
import line from './assets/line.png';
import couponVita from './assets/CouponVita.png';
import faceCoupon from './assets/face_Coupon.png';
import xCoupon from './assets/X.png';
import marcoCoupon from './assets/marco_Coupon.png';
import couponDashboard from './assets/icono_dashboard_coupon.png'
import check_register from './assets/check_register.png';
import filter from './assets/filter.png';
import emptyTransactions from './assets/empty_transactions.png';
import emptyNotifications from './assets/empty_notifications.png';
import BTCGray from './assets/btc_gray.png';
import arrowSelect from './assets/arrow_select.png';
import arrowSelectBlack from './assets/arrow_select_black.png';
import calendar from './assets/calendar.png';
import calendarBlack from './assets/calendar_black.png';
import checkSelect from './assets/check_select.png';
import historySuccessful from './assets/history_succesful.png';
import errorScreen from './assets/error_screen.png';
import userPlusWhite from './assets/user_plus_white.png';
import money from './assets/money.png';
import Sol from './assets/S.png';
import dollar from './assets/dollar.png';
import dollarBlack from './assets/dollar_black.png';
import btc from './assets/btc.png';
import checkError from './assets/check_error.png';
import checkSuccess from './assets/check_success.png';
import checkUnChecked from './assets/check_unChecked.png';
import dollarGreen from './assets/dollar_green.png';
import btcGreen from './assets/btc_green.png';
import solGreen from './assets/sol_green.png';
import scannerLabel from './assets/scanner_label.png';
import scannerLabelMovil from './assets/scanner_label_movil.png';
import alertGray from './assets/alert_gray.png';
import alertDarkGray from './assets/alert_dark_gray.png';
import alertBlue from './assets/alert_blue.png';
import alertRed from './assets/alert_red.png';
import alertClock from './assets/alert_clock.png';
import equalSpecial from './assets/equal_special.png';
import round_check from './assets/radio_button_on.png';
import round_uncheck from './assets/radio_button_off.png';
import btcOn from './assets/btc_on.png';
import dollarOn from './assets/dollar_on.png';
import cardBall from './assets/card_ball.png';
import cardReCall from './assets/card_re_ball.png';
import rCardSuccess from './assets/r_card_success.png';
import rCardActivateSuccess from './assets/r_card_activate_success.png';
import rActCard from './assets/r_act_card.png';
import rPinCard from './assets/r_pin_card.png';
import selectCard from './assets/select_card.png';
import selectCardBlack from './assets/select_card_black.png';
import selectGeneral from './assets/select_general.png';
import lock from './assets/lock.png';
import key from './assets/key.png';
import barFavorite from './assets/bar_favorite.png';
import pse from './assets/PSE.png';
import rCard1 from './assets/r_card_1.png';
import rCard1Fail from './assets/r_card_1_fail.png';
import rCard2 from './assets/r_card_2.png';
import rCard2Fail from './assets/r_card_2_fail.png';
import rActivateCard from './assets/r_activate_card.png';
import rSetPinCard from './assets/r_set_pin_card.png';
import ellipseBlack from './assets/ellipse_black.png';
import walletBlack from './assets/wallet_black.png';
import successfulCardRecharge from './assets/successful_card_recharge.png';
import deviceMobile from './assets/device_mobile.png';
import deviceDesktop from './assets/device_desktop.png';
import vitaLoaderAnimated from './assets/load_animated_circle.gif';
import searchIcon from './assets/search_icon.png';
import smile from './assets/smile.png';
import voucherDecoV2 from './assets/voucher_deco_v2.png';
import burgerMenu from './assets/burger_menu.png';
import supportIcon from './assets/support_icon.png';
import flag from './assets/flag.png';
import target from './assets/target.png';
import arrowLeft from "./assets/arrow_left.png";
import tag from './assets/tag.png';
import helpCircle from './assets/help-circle.png';
import helpCircleWhite from './assets/help-circle-white.png';
import bgActivateCoupon from './assets/bg_activate_coupon.png';
import successfulChange from './assets/successful_change.png';
import alertOrange from './assets/alert_orange.png';
import settings from './assets/settings.png';
import logout from './assets/logout.png';
import card from './assets/card.png';
import cardWhite from './assets/card_white.png';
import alertT from './assets/alert_t.png';
import alertCircle from './assets/alert_circle.png';
import unCheck from './assets/un_check.png';
import eyeOff from './assets/eye_off.png';
import eye from './assets/eye.png';
import badRequest from './assets/bad_request.png';
import uncheckBox from './assets/uncheck_box.png';
import checkBox from './assets/check_box.png';
import emailScreen from './assets/email_screen.png';
import vitaDraw from './assets/vita_draw.png';
import rCardError from './assets/r_card_error.png';
import rCardSuccessN from './assets/r_card_success_n.png';
import copy from './assets/copy.png';
import deleteKeyboard from './assets/delete_keyboard.png';
import qr from './assets/qr.png';
import upsError from './assets/ups_error.png';
import vitaV from './assets/vita_v.png';
import biometric from './assets/biometric.png';
import peopleLoading from './assets/people_loading.png';
import inProgress from './assets/in_progress.png';
import vitaVStars from './assets/vita_v_stars.png';
import notAvailable from './assets/not_available.png';
import star from './assets/star.png';
import boySmaile from './assets/boy_smaile.png';
import logoWhatsApp from './assets/wsApp.png';
import logoSupportVita from './assets/Intercom.png';
import arrowSelectChevron from './assets/arrow_select_chevron.png';
import refresh from './assets/refresh.png';
import addFavorite from './assets/addFavorite.png';
import deleteFavorite from './assets/deleteFav.png';
import updateFavorite from './assets/updateFav.png';
import updateFavoriteV2 from './assets/updateFav2.png';
import vitaAlex from './assets/vita_alex.png';
import vitaAlexChill from './assets/vita_alex_chill.png';
import vitaAlexSad from './assets/vita_alex_sad.png';
import alexSmile from './assets/alex_smile.png';
import lauraChill from './assets/laura_chill.png';
import bgLogin from './assets/bg_new_login.png';
import upload from './assets/upload.png';
import pdf from './assets/pdf.png';
import uploadDNI from './assets/upload_dni.png';
import uploadBackDNI from './assets/upload_back_dni.png';
import uploadFacial from './assets/upload_facial.png';
import defaultAvatar from './assets/default_avatar.png';
import plusCircle from './assets/plus_circle.png';
import copyCircle from './assets/copy_circle.png';
import copyBlue from './assets/copy_blue.png';
import shareCircle from './assets/share_circle.png';
import userPlusDark from './assets/user_plus_dark.png';
import userDouble from './assets/user_double.png';
import arrowLeftBack from './assets/arrow_left_back.png';
import bankIcon from './assets/bank_icon.png';
import deleteIcon from './assets/delete.png';
import happyMan from './assets/happy_man.png';
import image_list_requirements from './assets/image_list_requirements.png';
import photoSelfie from './assets/photo_selfie.png';
import Add from './assets/add.png';
import edit_red from './assets/edit_red.png';
import biometricRequest from './assets/biometric_request.png';
import requestBusiness from './assets/request_business.png'
import binancePay from './assets/binance_pay_logo.png';
import iCSV from './assets/i_csv.png';
import iPDF from './assets/i_pdf.png';
import iXLS from './assets/i_xls.png';
import vitaCardFlag from './assets/vita_card_flag.png';
import dropdownDots from './assets/dropdown_dots.png';
import vitaNoaHandsUp from './assets/vita_noa_hands_up.png';
import paulExplain from './assets/paul_explain.png';
import edit from './assets/edit.png';
import thunderGreen from './assets/thunder_green.png';
import vcIcon from './assets/vc_icon.png';
import vitaPaulSad from './assets/vita_paul_sad.png';
import freeze from './assets/freeze.png';
import freezeBlue from './assets/freeze_blue.png';
import trash from './assets/trash.png';
import checkGray from './assets/check_gray.png';
import zap from './assets/zap.png';
import informationHelper from './assets/information_helper.png';

// TODELETE
import netflix from "./assets/zzz_netflix.png";

// Banners 
import bannerVitaCard from './assets/banners/banner_vita_card.png';
import bannerVitaCardHeroes from './assets/banners/banner_vc_heroes.png';
import bannerActivateCard from './assets/banners/banner_activate_card.png';
import bannerSetPinCard from './assets/banners/banner_set_pin_card.png';
import bannerRecharge from './assets/banners/banner_recharge.png';
import bannerRechargeAngie from './assets/banners/banner_recharge_angie.png';
import bannerTether from './assets/banners/banner_tether.png';
import bannerCrypto from './assets/banners/banner_crypto.png';
import bannerCryptoAngie from './assets/banners/banner_crypto_angie.png';
import bannerVcActiveAngie from './assets/banners/banner_vc_active_angie.png';
import bannerVcAngie from './assets/banners/banner_vc_angie.png';
import bannerVcHeroesActiveAngie from './assets/banners/banner_vc_heroes_active_angie.png';
import bannerVcHeroesAngie from './assets/banners/banner_vc_heroes_angie.png';
import bannerVcHeroesRetryAngie from './assets/banners/banner_vc_heroes_retry_angie.png';
import bannerVcHeroesSetPinAngie from './assets/banners/banner_vc_heroes_set_pin_angie.png';
import bannerVcRetryAngie from './assets/banners/banner_vc_retry_angie.png';
import bannerVcSetPinAngie from './assets/banners/banner_vc_set_pin_angie.png';
import bannerVcCo from './assets/banners/banner_co.png';
import bannerVcCoAngie from './assets/banners/banner_co_angie.png';
import bannerUsdAccount from './assets/banners/banner_usd_account.png';
import bannerUsdAccountAngie from './assets/banners/banner_usd_account_angie.png';
import bannerUsdAccountActive from './assets/banners/banner_usd_account_active.png';
import bannerUsdAccountActiveAngie from './assets/banners/banner_usd_account_active_angie.png';
// Services 
import sGas from './assets/services/s_gas.png';
import sLight from './assets/services/s_light.png';
import sWater from './assets/services/s_water.png';
import sCatal from './assets/services/s_catal.png';
import sConectivity from './assets/services/s_conectivity.png';
import sCoop from './assets/services/s_coop.png';
import sEducation from './assets/services/s_education.png';
import sFinance from './assets/services/s_finance.png';
import sFuneral from './assets/services/s_funeral.png';
import sHealth from './assets/services/s_health.png';
import sInsurance from './assets/services/s_insurance.png';
import sRent from './assets/services/s_rent.png';
import sSecurity from './assets/services/s_security.png';
import sWays from './assets/services/s_ways.png';
import cBgService from './assets/services/c_bg.png';
import cGas from './assets/services/c_gas.png';
import cInternet from './assets/services/c_internet.png';
import cLight from './assets/services/c_light.png';
import cNetflix from './assets/services/c_netflix.png';
import cOther from './assets/services/c_other.png';
import cPhone from './assets/services/c_phone.png';
import cPs from './assets/services/c_ps.png';
import cTv from './assets/services/c_tv.png';
import cWater from './assets/services/c_water.png';
import cXbox from './assets/services/c_xbox.png';

// Crypto 
import exchangeArrows from './assets/exchange_arrows.png';
import exchangeSuccessful from './assets/exchange_successful.png';
import cryptoIcon from './assets/crypto_icon.png';
import cryptoOn from './assets/crypto_on.png';

// SideBar 
import cardActive from './assets/sidebar/card_active.png';
import cardInActive from './assets/sidebar/card_inactive.png';
import couponActive from './assets/sidebar/coupon_active.png';
import couponInActive from './assets/sidebar/coupon_inactive.png';
import cryptoActive from './assets/sidebar/crypto_active.png';
import cryptoInActive from './assets/sidebar/crypto_inactive.png';
import favsActive from './assets/sidebar/favs_active.png';
import favsInActive from './assets/sidebar/favs_inactive.png';
import homeActive from './assets/sidebar/home_active.png';
import homeInActive from './assets/sidebar/home_inactive.png';
import receiveActive from './assets/sidebar/receive_active.png';
import receiveInActive from './assets/sidebar/receive_inactive.png';
import rechargeActive from './assets/sidebar/recharge_active.png';
import rechargeInActive from './assets/sidebar/recharge_inactive.png';
import sendActive from './assets/sidebar/send_active.png';
import sendInActive from './assets/sidebar/send_inactive.png';
import shareActive from './assets/sidebar/share_active.png';
import shareInActive from './assets/sidebar/share_inactive.png';
import vitaActive from './assets/sidebar/vita_active.png';
import vitaInActive from './assets/sidebar/vita_inactive.png';
import serviceActive from './assets/sidebar/service_active.png';
import serviceInActive from './assets/sidebar/service_inactive.png';
import balancesActive from './assets/sidebar/balances_active.png';
import balancesInActive from './assets/sidebar/balances_inactive.png';
import alertClockBlack from './assets/clock_black.png';
import paymentLinkActive from './assets/sidebar/payment_link_active.png';
import paymentLinkInactive from './assets/sidebar/payment_link_inactive.png';

// Notifications 
import bubbleChat from './assets/notifications/bubble_chat.png';
import bubbleBadNotification from './assets/notifications/bubble_bad_notification.png';

// Lots
import vitauserLot from './assets/vita_users_lots.png';
import iconLotWhite from './assets/Icon_lot_white.png';
import iconLotBlack from './assets/Icons_lot_black.png';
import iconInfo from './assets/info.png';

// Dashboard
import iSend from './assets/dashboard/i_send.png';
import iSendAnimated from './assets/dashboard/i_send_animated.gif';
import iReceive from './assets/dashboard/i_receive.png';
import iExchange from './assets/dashboard/i_exchange.png';
import iService from './assets/dashboard/i_service.png';

// Vita Card
import vcCoB2B from './assets/vita_card/vc_co_b2b.png';
import vcCoB2C from './assets/vita_card/vc_co_b2c.png';
import frozenCard from './assets/vita_card/frozen_card.png';

import loader from './assets/loader.gif';

export const Assets = {
  images: {
    loader,
    round_check,
    round_uncheck,
    userPlus,
    userPlusOff,
    defaultPhoto,
    arrowRight,
    close,
    closeBlue,
    closeWhite,
    elbaDice,
    userX,
    userCheck,
    checkDark,
    checkBlue,
    checkWhite,
    vitaLogo,
    vitaLogoGray,
    worm,
    check,
    checkOrange,
    xCheck,
    uncheck,
    check_register,
    filter,
    emptyTransactions,
    emptyNotifications,
    BTCGray,
    arrowSelect,
    arrowSelectBlack,
    calendar,
    calendarBlack,
    checkSelect,
    historySuccessful,
    errorScreen,
    userPlusWhite,
    money,
    Sol,
    dollar,
    dollarBlack,
    checkError,
    checkSuccess,
    checkUnChecked,
    dollarGreen,
    btcGreen,
    solGreen,
    scannerLabel,
    scannerLabelMovil,
    alertGray,
    alertDarkGray,
    alertBlue,
    alertRed,
    alertClock,
    alertClockBlack,
    equalSpecial,
    btc,
    btcOn,
    dollarOn,
    bannerVitaCard,
    bannerVitaCardHeroes,
    bannerActivateCard,
    bannerSetPinCard,
    cardBall,
    cardReCall,
    bannerRecharge,
    bannerRechargeAngie,
    rCardSuccess,
    rCardActivateSuccess,
    selectCard,
    selectGeneral,
    lock,
    key,
    netflix,
    barFavorite,
    pse,
    rCard1,
    rCard1Fail,
    rCard2,
    rCard2Fail,
    rActivateCard,
    rActCard,
    rPinCard,
    rSetPinCard,
    ellipseBlack,
    walletBlack,
    selectCardBlack,
    successfulCardRecharge,
    deviceMobile,
    deviceDesktop,
    vitaLoaderAnimated,
    searchIcon,
    sGas,
    sLight,
    sWater,
    sCatal,
    sConectivity,
    sCoop,
    sEducation,
    sFinance,
    sFuneral,
    sHealth,
    sInsurance,
    sRent,
    sSecurity,
    sWays,
    cBgService,
    cGas,
    cInternet,
    cLight,
    cNetflix,
    cOther,
    cPhone,
    cPs,
    cTv,
    cWater,
    cXbox,
    smile,
    voucherDecoV2,
    burgerMenu,
    supportIcon,
    couponIcon,
    line,
    couponVita,
    faceCoupon,
    xCoupon,
    marcoCoupon,
    couponDashboard,
    flag,
    target,
    arrowLeft,
    tag,
    helpCircle,
    helpCircleWhite,
    bgActivateCoupon,
    successfulChange,
    alertOrange,
    settings,
    logout,
    card,
    cardWhite,
    alertT,
    alertCircle,
    unCheck,
    eyeOff,
    eye,
    badRequest,
    uncheckBox,
    checkBox,
    emailScreen,
    vitaDraw,
    rCardError,
    rCardSuccessN,
    copy,
    deleteKeyboard,
    qr,
    upsError,
    vitaV,
    biometric,
    peopleLoading,
    inProgress,
    vitaVStars,
    notAvailable,
    star,
    boySmaile,
    bannerTether,
    exchangeArrows,
    exchangeSuccessful,
    cryptoIcon,
    cryptoOn,
    logoWhatsApp,
    logoSupportVita,
    arrowSelectChevron,
    refresh,
    addFavorite,
    deleteFavorite,
    updateFavorite,
    updateFavoriteV2,
    vitaAlex,
    vitaAlexChill,
    bgLogin,
    upload,
    pdf,
    uploadDNI,
    uploadBackDNI,
    uploadFacial,
    cardActive,
    cardInActive,
    couponActive,
    couponInActive,
    cryptoActive,
    cryptoInActive,
    favsActive,
    favsInActive,
    homeActive,
    homeInActive,
    receiveActive,
    receiveInActive,
    rechargeActive,
    rechargeInActive,
    sendActive,
    sendInActive,
    shareActive,
    shareInActive,
    vitaActive,
    vitaInActive,
    serviceActive,
    serviceInActive,
    bannerCrypto,
    bannerCryptoAngie,
    bannerVcActiveAngie,
    bannerVcAngie,
    bannerVcHeroesActiveAngie,
    bannerVcHeroesAngie,
    bannerVcHeroesRetryAngie,
    bannerVcHeroesSetPinAngie,
    bannerVcRetryAngie,
    bannerVcSetPinAngie,
    defaultAvatar,
    plusCircle,
    copyCircle,
    copyBlue,
    shareCircle,
    userPlusDark,
    userDouble,
    arrowLeftBack,
    bankIcon,
    bubbleChat,
    bubbleBadNotification,
    deleteIcon,
    edit_red,
    biometricRequest,
    requestBusiness,
    image_list_requirements,
    binancePay,
    happyMan,
    vitaAlexSad,
    lauraChill,
    alexSmile,
    vitauserLot,
    iconLotWhite,
    iconLotBlack,
    iconInfo,
    iSend,
    iSendAnimated,
    iReceive,
    iExchange,
    iService,
    iCSV,
    iPDF,
    iXLS,
    photoSelfie,
    Add,
    balancesActive,
    balancesInActive,
    paymentLinkActive,
    paymentLinkInactive,
    vitaCardFlag,
    dropdownDots,
    vitaNoaHandsUp,
    paulExplain,
    edit,
    thunderGreen,
    bannerVcCo,
    bannerVcCoAngie,
    vcIcon,
    vitaPaulSad,
    bannerUsdAccount,
    bannerUsdAccountAngie,
    vcCoB2B,
    vcCoB2C,
    freeze,
    freezeBlue,
    trash,
    frozenCard,
    checkGray,
    zap,
    informationHelper,
    bannerUsdAccountActive,
    bannerUsdAccountActiveAngie,
  },
};

export const Colors = {
  C_000000: '#000000',
  C_00BB8D: '#00BB8D',
  C_777777: '#777777',
  C_4F4F4F: '#4F4F4F',
  C_333333: '#333333',
  C_BDBDBD: '#BDBDBD',
  C_FFFFFF: '#FFFFFF',
  C_FAFAFA: '#FAFAFA',
  C_F2994A: '#F2994A',
  C_005FEE: '#005FEE',
  C_828282: '#828282',
  C_F2F2F2: '#F2F2F2',
  C_FFFBF4: '#FFFBF4',
  C_14AD92: '#14AD92',
  C_6C6C6C: '#6C6C6C',
  C_807F83: '#807F83',
  C_F4F4F4: '#F4F4F4',
  C_F2FFF0: '#F2FFF0',
  C_FFFCF2: '#FFFCF2',
  C_EDEFF3: '#EDEFF3',
  C_99A6BA: '#99A6BA',
  C_FF0000: '#FF0000',
  C_4984DC: '#4984DC',
  C_DD5335: '#DD5335',
  C_AFA90B: '#AFA90B',
  C_27AE10: '#27AE10',
  C_FCFDFF: '#FCFDFF',
  C_9966CC: '#9966CC',
  C_40B49F: '#40B49F',
  C_F1F4F9: '#F1F4F9',
  C_BABABA: '#BABABA',
  C_F5F5F5: '#F5F5F5',
  C_F4F6F8: '#F4F6F8',
  C_EAEDF1: '#EAEDF1',
  C_F8F9FA: '#F8F9FA',
  C_BDDCDC: '#BDDCDC',
  C_DBDFE6: '#DBDFE6',
  C_1C3965: '#1C3965',
  C_885FBB: '#885FBB',
  C_472375: '#472375',
  C_BA6C9B: '#BA6C9B',
  C_913D6F: '#913D6F',
  C_5D7AC6: '#5D7AC6',
  C_1A357C: '#1A357C',
  C_ECEFFA: '#ECEFFA',
  C_3E5ECD: '#3E5ECD',
  C_FAE4DF: '#FAE4DF',
  C_FAFBFD: '#FAFBFD',
  C_F8FAFC: '#F8FAFC',
  C_211135: '#211135',
  C_01684E: '#01684E',
  C_3E0438: '#3E0438',
  C_0D2562: '#0D2562',
  C_07C999: '#07C999',
  C_05A27C: '#05A27C',
  C_21DCAE: '#21DCAE',
  C_E5F7E2: '#e5f7e2',
  C_A4B0C1: '#A4B0C1',
  C_8B6EF4: '#8B6EF4',
  C_300FA9: '#300FA9',
  C_422B97: '#422B97',
  C_176496: '#176496',
  C_1BE8C9: '#1BE8C9',
  C_00A68D: '#00A68D',
  C_014AB9: '#014AB9',
  C_001F4F: '#001F4F',
  C_E3E7EC: '#E3E7EC',
  C_0BCE9E: '#0BCE9E',
  C_05A17B: '#05A17B',
  C_27AE10_8: 'rgba(39, 174, 16, 0.08)',
  C_DD5335_16: 'rgba(221, 83, 53, 0.16)',
  C_DED621_16: 'rgba(222, 214, 33, 0.16)',
  C_5CCD49_16: 'rgba(92, 205, 73, 0.16)',
  C_FFBE00_X: (X) => (`rgba(255, 190, 0, ${X})`),
  C_FFFFFF_X: (X) => (`rgba(255,255,255, ${X})`),
  C_1C3965_X: (X) => (`rgba(28, 57, 101, ${X})`),
  C_00BB8D_X: (X) => (`rgba(0, 187, 141, ${X})`),
  linear_white: ['#FFFFFF', '#FFFFFF'],
  linear_gradient_orange: ['#F2994A', '#F2994A'],
  linear_gradient_gray: ['#F2F2F2', '#F2F2F2'],
  linear_gradient_qr_code: ['#00BB8D', '#005FEE'],
  linear_gradient_qr_code_inverse: ['#005FEE', '#00BB8D'],
  linear_white_transparent: [
    "rgba(255,255,255,0)",
    "rgba(255,255,255,0.2)",
    "rgba(255,255,255,0.8)",
    "#FFFFFF",
  ],
  linear_white_transparent_full: [
    "rgba(255,255,255,0)",
    "rgba(255,255,255,0.8)",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
  ],
  linear_white_transparent_footer: [
    "rgba(255,255,255,0)",
    "rgba(255,255,255,0.1)",
    "rgba(255,255,255,0.6)",
    "rgba(255,255,255,0.6)",
    "rgba(255,255,255,0.6)",
    "rgba(255,255,255,0.8)",
    "#FFFFFF",
  ],
  input_lrgt_init: [
    '#BDBDBD',
    '#BDBDBD',
  ],
  input_lrgt_completed: [
    '#777777',
    '#777777',
  ],
  input_lrgt_error: [
    '#F2994A',
    '#F2994A',
  ],
  input_lrgt_focus: [
    '#005FEE',
    '#00BB8D',
  ],
  input_lrgt_disabled: [
    '#F2F2F2',
    '#F2F2F2',
  ],
  linear_gradient_black: [
    'rgba(0,0,0,0)',
    'rgba(0,0,0,0.19)',
    'rgba(0,0,0,0.5)',
  ],
};

export const Fonts = {
  regular: "Lato-Regular",
  black: "Lato-Black",
  bold: "Lato-Bold",
  medium: "Lato-Medium",
  variableBlack: "VariableBlack",
};

export const FontSizes = {
  fontExtraSmall: 8,
  fontLightSmall: 10,
  fontSmall: 12,
  fontNormal: 14,
  fontExtraNormal: 16,
  fontMed: 18,
  fontMax: 20,
  fontExtraMax: 22,
  fontBig: 24,
  fontLightMax: 32,
  fontUltraMax: 34,
};

export const flexRow = {
  display: 'flex',
  flexDirection: 'row',
}

export const flexRowReverse = {
  display: 'flex',
  flexDirection: 'row-reverse',
}

export const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
}

export const flexAuto = {
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  flexBasis: 'auto',
};

export const flexCoverAuto = {
  display: 'flex',
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: 'auto',
};

export const flexCoverMiddle = {
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '50%',
}

export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ShadowProperties =
  Platform.OS === "ios"
    ? {
      shadowColor: Colors.C_000000,
      shadowOffset: {
        width: 2,
        height: 4,
      },
      shadowOpacity: 0.12,
      shadowRadius: 16,
      elevation: 10,
      overflow: "visible",
    }
    : {
      shadowColor: Colors.C_000000,
      shadowOffset: {
        width: 2,
        height: 4,
      },
      shadowOpacity: 0.12,
      shadowRadius: 16,
      elevation: 15,
    };
