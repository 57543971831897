// MANUAL CLONE 06
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { MaskService } from 'react-native-masked-text';
import { milliseconds_call } from '../config/constants';
import { FREQUENT_ACCOUNTS_KEY } from '../contexts/state';

export const IS_PIN_AUTH = Platform.OS !== 'web';
export const USD_RATE_ROUTES = ['AR'];
export const SECONDS_WAIT_PRICES = 5;

/* eslint-disable no-console */
const log = (name, data) => {
  if (data) {
  }
};
/* eslint-enable no-console */

export { log };

export const addEllipsis = (value = '', cantDigits = 17) => {
  value = value || '';
  if (value.length > cantDigits) {
    return `${value.slice(0, 14)}...`;
  }

  return value;
};

export const pricesInitials = {
  clp: 1,
  btc: 1,
  btc_sell: 1,
  btc_buy: 1,
  eth: 1,
  eth_sell: 1,
  eth_buy: 1,
  usd: 1,
  usd_sell: 1,
  usd_buy: 1,
  valid_until: null,
};

export const isoCodeCountries = {
  Argentina: 'ar',
  Bolivia: 'bo',
  Chile: 'cl',
  Colombia: 'co',
  Ecuador: 'ec',
  México: 'mx',
  Panamá: 'pa',
  Paraguay: 'py',
  Perú: 'pe',
  'Perú (USD)': 'peusd',
  Uruguay: 'uy',
  Venezuela: 've',
  'Estados Unidos': 'us',
  'Estados Unidos - WIRE': 'us',
  'Reino Unido': 'gb',
  'Brasil': 'br',
  'China': 'cn',
  'Costa Rica': 'cr',
  'República Dominicana': 'do',
  'Haití': 'ht',
};

export const currencies = {
  rate: {
    code: 'RATE',
    name: 'Rate',
    namePlural: 'Rate',
    precision: 4,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 0.00001,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  btc: {
    code: 'BTC',
    name: 'Bitcoin',
    namePlural: 'Bitcoin',
    precision: 8,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 0.00001,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  clp: {
    code: 'CLP',
    name: 'Peso chileno',
    namePlural: 'Pesos chilenos',
    precision: 0,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 701,
      },
      receive: {
        minimumAmount: 2500,
      },
      recharge: {
        minimumAmount: 2500,
        fee: 3.5,
      },
    },
  },
  mxn: {
    code: 'MXN',
    name: 'Peso mexicano',
    namePlural: 'Pesos mexicanos',
    precision: 0,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  ves: {
    code: 'VES',
    name: 'Bolívar soberano',
    namePlural: 'Bolivares soberanos',
    precision: 0,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  pen: {
    code: 'PEN',
    name: 'Sol peruano',
    namePlural: 'Soles peruanos',
    precision: 0,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  penusd: {
    code: 'USD',
    countryName: 'Perú (USD)',
    name: 'Dolar americano',
    namePlural: 'Dolares americanos',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 50,
      },
      receive: {
        minimumAmount: 50,
      },
      recharge: {
        minimumAmount: 50,
        fee: 3.5,
      },
    },
  },
  cop: {
    code: 'COP',
    name: 'Peso colombiano',
    namePlural: 'Pesos colombianos',
    precision: 0,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  ars: {
    code: 'ARS',
    name: 'Peso argentino',
    namePlural: 'Pesos argentinos',
    precision: 0,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  usd: {
    code: 'USD',
    name: 'Dólar estadounidense',
    namePlural: 'Dólares',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 15,
      },
      recharge: {
        minimumAmount: 15,
        fee: 3.5,
      },
    },
  },
  gbp: {
    code: 'GBP',
    name: 'Libra esterlina',
    namePlural: 'Libras esterlinas',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  pab: {
    code: 'PAB',
    name: 'Balboa panameño',
    namePlural: 'Balboas panameños',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  brl: {
    code: 'BRL',
    name: 'Real brasileño',
    namePlural: 'Reales',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  ecs: {
    code: 'ECS',
    name: 'Dolar',
    namePlural: 'Dolares',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  cny: {
    code: 'CNY',
    name: 'Yuan',
    namePlural: 'Yuanes',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  bob: {
    code: 'BOB',
    name: 'Boliviano',
    namePlural: 'Bolivianos',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  crc: {
    code: 'CRC',
    name: 'Colón costarricense',
    namePlural: 'Colones',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  dop: {
    code: 'DOP',
    name: 'Peso Dominicano',
    namePlural: 'Pesos Dominicanos',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 1,
      },
      receive: {
        minimumAmount: 0.00001,
      },
      recharge: {
        minimumAmount: 0.00001,
        fee: 3.5,
      },
    },
  },
  htg: {
    code: 'USD',
    countryName: 'Haití',
    name: 'Dólar americano',
    namePlural: 'Dólares americanos',
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '',
    suffixUnit: '',
    transactions: {
      exchange: {
        minimumAmount: 50,
      },
      receive: {
        minimumAmount: 50,
      },
      recharge: {
        minimumAmount: 50,
        fee: 3.5,
      },
    },
  },
};

export const formatMoney = (amount, currency = 'clp', precision = 0, isRate) => {
  precision = isRate ? 2 : precision;

  amount = parseFloat(amount && amount >= 0 ? amount : 0);

  if (amount.toString().includes('e-')) {
    amount = amount.toFixed(20)
  }

  const m = amount.toString().match(new RegExp(`(\\d+\\.\\d{${precision}})`, 'g'));

  amount = m ? parseFloat(m[0]) : amount.valueOf();

  amount = MaskService.toMask('money', amount, {
    precision,
    separator: ','/* currency === 'usd' ? '.' : ',' */,
    delimiter: '.'/* currency === 'usd' ? ',' : '.' */,
    unit: '',
    suffixUnit: '',
  });

  return amount;
};

function toFixed(x) {
  var e;
  if (Math.abs(x) < 1.0) {
    e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

// export const randomProperty = function (obj) {
//   var keys = Object.keys(obj);
//   return obj[keys[ keys.length * Math.random() << 0]];
// };

export const applyDecimals = (amount, currency, precision = 0) => {
  currency = currency ? currency : 'clp';
  amount = toFixed(amount);
  currency = currency.toLowerCase();
  amount = amount
    .toString()
    .match(new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`))[0];
  if (precision === 0) {
    amount = parseInt(amount);
  } else {
    amount = parseFloat(amount).toFixed(precision);
  }
  return amount;
};

export const decimalRound = (value, decimals) => {
  const multiplier = Math.pow(10, decimals);
  const adjust = value * multiplier;

  value = Math.ceil(adjust) + 1;

  return value / multiplier;
};

export const PURPOSE_CODES = [
  { value: 'EPFAMT', label: 'Mantenimiento familiar' },
  { value: 'ISMDCS', label: 'Pago por servicios de atención médica' },
  { value: 'ISSTDY', label: 'Pago de costos de estudio/matrícula' },
  { value: 'ISCHAR', label: 'Pago por razones de caridad' },
  { value: 'EPPROP', label: 'Pago de compra de propiedad' },
  { value: 'EPSHAR', label: 'Pago de acciones' },
  { value: 'EPIVST', label: 'Pago de una inversión' },
  { value: 'ISUBIL', label: 'Pago al proveedor de servicios públicos comunes' },
  { value: 'ISTAXS', label: 'Pago de impuestos' },
  { value: 'EPTOUR', label: 'Turismo' },
  { value: 'ISSAVG', label: 'Pago a cuenta de ahorro/jubilación' },
  { value: 'ISPENS', label: 'Pago de pensión' },
  { value: 'ISPAYR', label: 'Pago de nómina' },
  { value: 'ISGDDS', label: 'Compra venta de bienes' },
  { value: 'ISSUPP', label: 'Pago del proveedor' },
  { value: 'EPREMT', label: 'Remesas' },
  { value: 'ISSCVE', label: 'Compra venta de servicios' },
];

export const TYPE_DOCUMENTS = {
  AR: [
    { value: 'cuit', label: 'CUIT' },
    { value: 'cuil', label: 'CUIL' },
  ]
};

const currenciesRegex = '((usdc|usdt|btc|usd|clp|cop|mxn|ars):)?';

export const regEx = {
  btc: `^${currenciesRegex}(?:[a-zA-Z0-9]{24,70})$`,
  usdc_usdt: `^${currenciesRegex}((0x)|(T))+[0-9A-Za-z\\-_]{1,120}$`,
  MATIC_BSC_ETH: `^0x[a-fA-F0-9]{40}$`,
  TRX: `^${currenciesRegex}T[a-zA-Z0-9]{33}$`,
  SOLANA: `^${currenciesRegex}[a-zA-Z0-9]{44}$`,
  ALGORAN: `^${currenciesRegex}[A-Z2-7]{58}$`,
  email: `^${currenciesRegex}[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$`,
};


export const qrResolveFromVita = (QR) => {
  try {
    let maped = true;
    let index = 0;
    const regexArray = Object.values(regEx);
    let result = {};

    while (maped || index < regexArray.length) {
      const regExp = regexArray[index];
      const testRegExp = new RegExp(regExp).test(QR);

      if (testRegExp && regExp) {
        maped = false;

        if (QR.includes(':')) {
          const splitQR = QR.split(':');
          result.currency = splitQR[0];
          result.address = splitQR[1];
        } else {
          result.address = QR;
        }
      } else if (!regExp) {
        throw new Error('Código QR invalido.');
      }

      index += 1;
    }

    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const isValidEmail = (email = '') => {
  return new RegExp(regEx.email).test(email.trim());
};

export const isValidBtcWallet = (wallet = '') => {
  return new RegExp(regEx.btc).test(wallet.trim());
};

export const isValidUsdtAddress = (address = '') => {
  return new RegExp(regEx.usdc_usdt).test(address.trim())
}

export const isValidUsdcAddress = (address = '') => {
  return new RegExp(regEx.usdc_usdt).test(address.trim())
}

export const minimumAmountTransferCurrencies = {
  cop: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  clp: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  mxn: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  ves: {
    clp: 2500,
    usd: 1,
    cop: 2000
  },
  pen: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  penusd: {
    clp: 15000,
    usd: 20,
  },
  usd: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  gbp: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  ars: {
    clp: 2500,
    usd: 5,
    cop: 15000
  },
  bob: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  pab: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  pyg: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  uyu: {
    clp: 15000,
    usd: 20,
    cop: 20000
  },
  brl: {
    clp: 15000,
    usd: 20,
  },
  ecs: {
    clp: 15000,
    usd: 20,
  },
  cny: {
    clp: 15000,
    usd: 20,
  },
  crc: {
    clp: 15000,
    usd: 20,
  },
  dop: {
    clp: 15000,
    usd: 20,
  },
  htg: {
    clp: 45000,
    usd: 50,
  },
};

export const logOutForce = (error = null, config = null) => {
  if (
    Platform.OS === 'web' &&
    error &&
    error.response &&
    error.response.status &&
    error.response.status.toString() === '401'
  ) {

    (async () => {
      try {
        await AsyncStorage.clear();

        const request = indexedDB.open('md');
        request.onsuccess = (event) => {
          const db = event.target.result;
          const transaction = db.transaction('st', 'readwrite');
          const store = transaction.objectStore('st');

          const clearRequest = store.clear();

          clearRequest.onsuccess = () => { };
          transaction.oncomplete = () => {
            db.close();

            if (config && config.url != '/auth/sign_in' && global && global.window) {
              global.window.location = '/login';
            }
          };
        };
      } catch { }
    })()
  }
};

export const onlyTwoWordsFromFullName = (firstName, lastName) => {
  firstName = firstName.toLowerCase();
  firstName = firstName.split(' ')[0];
  firstName = firstName[0].toUpperCase() + firstName.slice(1);

  if (lastName) {
    lastName = lastName.toLowerCase();
    lastName = lastName.split(' ')[0];
    lastName = lastName[0].toUpperCase() + lastName.slice(1);
  } else
    lastName = '';

  return `${firstName} ${lastName}`;
};

// export const removeLeadingZerosBtc = (amountBtc) => {
//   amountBtc = Array.from(amountBtc);
//   const tam = amountBtc.length - 1;
//   let deleteDigits = true;

//   for (let i = 0; i < tam && deleteDigits; i++) {
//     if (amountBtc[tam - i] !== '0') {
//       deleteDigits = false;
//     }

//     if (amountBtc[tam - i] === ',') {
//       amountBtc[tam - i] = 'D';
//     }

//     if (amountBtc[tam - i] === '0' && deleteDigits) {
//       amountBtc[tam - i] = 'D';
//     }
//   }

//   amountBtc = amountBtc.join('');
//   amountBtc = amountBtc.replace(/D/g, '');
//   return amountBtc;
// };

export const isNumber = (number) => {
  return /^\d+$/.test(number);
};

export const isTransferPricesRequired = (routeName) => {
  //'Dashboard' || ADD DASHBOARD IF CONSIDER NECESARY
  return ['Sent', 'CardRequest', 'UsdAccount'].includes(routeName);
}

export const isPricesRequired = (routeName) => {
  return ['Dashboard', 'Sent', 'Exchange', 'UsdAccount'].includes(routeName);
}

export const isBalancesUpdated = (routeName) => {
  return ['Balances', 'Sent', 'Exchange', 'Recharge'].includes(routeName);
}

export const isSessionExpired = (session, minutes = 0) => {
  const now = moment().toDate().getTime();
  const expiryTime = moment(session.expiry)
    .subtract(minutes, 'minute')
    .toDate()
    .getTime();
  return expiryTime < now;
};

export const isExpirySession = (date) => {
  if (date && IS_PIN_AUTH) {
    const now = moment();
    date = moment(date);
    return now > date;
  }

  return false;
};

export const waitingAction = (callback = () => { }, milliseconds = null) => {
  let timer;

  clearTimeout(timer);
  timer = setTimeout(() => {
    callback();
    clearTimeout(timer);
  }, milliseconds || milliseconds_call);

  return () => {
    clearTimeout(timer)
  };
}

export const specialFormat = /^[a-zA-Z0-9]*$/;

export const BLACK_LIST_CONTEXT = [FREQUENT_ACCOUNTS_KEY]

