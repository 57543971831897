import { useState } from 'react';
import useTransfer from './useTransfer';

const useTransferWrapper = (props = {}) => {
  const { transferFrequentAccount } = props;
  const [isTransferInformation, setTransferInformation] = useState(false);
  const [isBeneficiaryFinder, setBeneficiaryFinder] = useState(false);
  const [isBeneficiaryFetchButton, setBeneficiaryFetchButton] = useState(false);
  const [isAccountSelect, setAccountSelect] = useState(false);
  const [lockForm, setLockForm] = useState(null);
  const [isEmptyFrequentAccount, setEmptyFrequentAccount] = useState(false);
  const [frequentAccount, setFrequentAccount] = useState(null);
  const [isFrequents, setFrequents] = useState(false);

  const transferData = useTransfer(props) || {};
  const { rule = {} } = transferData;
  const { is_beneficiary_search = false, benericiary_search_label, beneficiary_search_provider, account_code_regex } = rule;

  const onFrequents = () => {
    setFrequents(true);
  }

  const onShowTransferInformation = () => {
    setBeneficiaryFinder(false);
    setTransferInformation(true);
  }

  const onCancelBeneficiarySearch = () => {
    setTransferInformation(false);
    setBeneficiaryFinder(false);
    setBeneficiaryFetchButton(false);
    setLockForm(null);
    setAccountSelect(isEmptyFrequentAccount ? false : true);
  };

  const changeTransferInformation = (value) => {
    if (!value) {
      setTransferInformation(false);

      if (!frequentAccount) {
        if (is_beneficiary_search) {
          setBeneficiaryFinder(true);
        } else {
          setAccountSelect(isFrequents)
        }
      } else {
        if (!transferFrequentAccount) {
          setAccountSelect(true)
          setFrequentAccount(null)
        } else {
          setAccountSelect(false)
          setBeneficiaryFinder(false)
        }
      }

      setBeneficiaryFetchButton(false);
      setLockForm(null);
      return
    }

    if (is_beneficiary_search && !isBeneficiaryFetchButton && !frequentAccount) {
      if (isBeneficiaryFinder) {
        setBeneficiaryFinder(value);
      } else {
        setBeneficiaryFinder(true);
        setTransferInformation(false);
      }
    } else {
      setTransferInformation(value);
    }
  }

  const onFetchBeneficiaryButton = (data, isError = false) => {
    setLockForm(data)
    setBeneficiaryFetchButton(true);
  }

  const onEmptyFrequentAccount = () => {
    setEmptyFrequentAccount(true);
  }

  const onSelectFrequentAccount = (value) => {
    if (value) {
      setBeneficiaryFinder(false)
    }

    setFrequentAccount(value)
  }

  return {
    ...transferData,
    onFrequents,
    frequentAccount,
    setFrequentAccount: onSelectFrequentAccount,
    isAccountSelect,
    setAccountSelect,
    isBeneficiaryFinder,
    beneficiarySearchLabel: benericiary_search_label,
    beneficiarySearchProvider: beneficiary_search_provider,
    accountCodeRegex: account_code_regex,
    isTransferInformation,
    onShowTransferInformation,
    setTransferInformation: changeTransferInformation,
    onFetchBeneficiaryButton,
    onCancelBeneficiarySearch,
    onEmptyFrequentAccount,
    lockForm,
    isLockForm: !!lockForm,
  }
};

export default useTransferWrapper;