import { useState, useMemo, useCallback } from 'react';
import config from '../config';

import useAmounts from './useAmounts';
import useResidenceConfig from './useResidenceConfig';
import useTransferPrices from './useTransferPrices';
import usePrices from './usePrices';

import vitaCardService from '../services/vitaCard';

import { VITA_CARD_STATUS } from 'wallet/util';

import { formatMoney } from '../utils';

const useVitaCard = (props) => {
  const { user } = props;
  const { useRules } = config.getInstance().getConfiguration();
  const { cryptoCurrencies, fiatCurrencies } = useRules();
  const { prices: fiatPrices } = useTransferPrices();
  const { prices: cryptoPrices } = usePrices();
  const { countryByCurrencyCode } = useAmounts();

  const {
    balances,
    default_fiat_currency,
    headers,
  } = user;
  const {
    card_costs,
  } = useResidenceConfig();

  const [currency, setCurrency] = useState(default_fiat_currency);
  const [errors, setErrors] = useState({});

  const currenciesAvailable = useMemo(() => {
    return [...fiatCurrencies, ...cryptoCurrencies];
  }, [cryptoCurrencies, fiatCurrencies]);

  const currencyConfig = useMemo(() => countryByCurrencyCode(currency), [currency, countryByCurrencyCode]);

  const cardCreationCost = useMemo(() => {
    let cardCost = card_costs?.card_cost || 0;
    const { is_crypto } = currencyConfig;

    if (is_crypto) {
      // TODO DAVID: Make functionality pay cost in crypto 
      console.log(cryptoPrices);
    } else {
      const price = fiatPrices.fiat['us'][`${currency.toLowerCase()}_spot`];
      cardCost = cardCost / price;
    }

    if (balances[currency] < cardCost) {
      setErrors(prev => ({ ...prev, cost: 'No tienes saldo suficiente' }));
    } else {
      setErrors(prev => ({ ...prev, cost: '' }));
    }

    return cardCost;
  }, [
    currency,
    card_costs,
    fiatPrices,
    cryptoPrices,
    currencyConfig,
    balances,
  ]);

  const cardCreationCostLabel = useMemo(() => {
    const { currency_decimals, currency_code } = currencyConfig;
    return formatMoney(cardCreationCost, currency_code, currency_decimals);
  }, [cardCreationCost, currencyConfig]);

  const showCardInformation = useCallback(async (id) => {
    try {
      const response = await vitaCardService.showVirtualCard(headers, id);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const freezeCard = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        status: VITA_CARD_STATUS.BLOCKED
      };
      const response = await vitaCardService.changeCardStatus(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const unfreezeCard = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        status: VITA_CARD_STATUS.ACTIVE
      };
      const response = await vitaCardService.changeCardStatus(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const deleteCard = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        status: VITA_CARD_STATUS.CANCELLED
      };
      const response = await vitaCardService.changeCardStatus(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const changeVitaCardCurrency = useCallback(async (cardID, currency) => {
    try {
      const data = {
        card_id: cardID,
        currency_iso_code: currency
      };
      const response = await vitaCardService.changeVitaCardCurrency(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers, currency]);

  const changeCurrency = (sendCurrency) => {
    setCurrency(sendCurrency.toLowerCase());
  };

  return ({
    currency,
    currenciesAvailable,
    changeCurrency,
    cardCreationCost,
    cardCreationCostLabel,
    errors,
    currencyConfig,
    showCardInformation,
    freezeCard,
    unfreezeCard,
    deleteCard,
    changeVitaCardCurrency,
  });
};

export default useVitaCard;