import { useState, useMemo, useCallback } from 'react';
import { NativeModules } from 'react-native';
import config from '../config';

import useAmounts from './useAmounts';
import useResidenceConfig from './useResidenceConfig';
import useTransferPrices from './useTransferPrices';
import usePrices from './usePrices';

import vitaCardService from '../services/vitaCard';

import { VITA_CARD_STATUS } from 'wallet/util';

import { formatMoney } from '../utils';

const useVitaCard = (props) => {
  const { user } = props;
  const { useRules } = config.getInstance().getConfiguration();
  const { cryptoCurrencies, fiatCurrencies } = useRules();
  const { prices: fiatPrices } = useTransferPrices();
  const { prices: cryptoPrices } = usePrices();
  const { countryByCurrencyCode } = useAmounts();
  const { WalletIDModule } = NativeModules;
  const {
    TOKEN_STATE_ACTIVE,
    getActiveWalletID,
    getStableHardwareId,
    isTokenized,
    listTokens,
    requestPushProvision
  } = WalletIDModule || {};
  const {
    balances,
    default_fiat_currency,
    headers,
  } = user;
  const {
    card_costs,
  } = useResidenceConfig();

  const [currency, setCurrency] = useState(default_fiat_currency);
  const [errors, setErrors] = useState({});

  const currenciesAvailable = useMemo(() => {
    return [...fiatCurrencies, ...cryptoCurrencies];
  }, [cryptoCurrencies, fiatCurrencies]);

  const currencyConfig = useMemo(() => countryByCurrencyCode(currency), [currency, countryByCurrencyCode]);

  const cardCreationCost = useMemo(() => {
    let cardCost = card_costs?.card_cost || 0;
    const { is_crypto } = currencyConfig;

    if (is_crypto) {
      // TODO DAVID: Make functionality pay cost in crypto 
      console.log(cryptoPrices);
    } else {
      const price = fiatPrices.fiat['us'][`${currency.toLowerCase()}_spot`];
      cardCost = cardCost / price;
    }

    if (balances[currency] < cardCost) {
      setErrors(prev => ({ ...prev, cost: 'No tienes saldo suficiente' }));
    } else {
      setErrors(prev => ({ ...prev, cost: '' }));
    }

    return cardCost;
  }, [
    currency,
    card_costs,
    fiatPrices,
    cryptoPrices,
    currencyConfig,
    balances,
  ]);

  const cardCreationCostLabel = useMemo(() => {
    const { currency_decimals, currency_code } = currencyConfig;
    return formatMoney(cardCreationCost, currency_code, currency_decimals);
  }, [cardCreationCost, currencyConfig]);

  const showCardInformation = useCallback(async (id) => {
    try {
      const response = await vitaCardService.showVirtualCard(headers, id);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const freezeCard = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        status: VITA_CARD_STATUS.BLOCKED
      };
      const response = await vitaCardService.changeCardStatus(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const unfreezeCard = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        status: VITA_CARD_STATUS.ACTIVE
      };
      const response = await vitaCardService.changeCardStatus(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const deleteCard = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        status: VITA_CARD_STATUS.CANCELLED
      };
      const response = await vitaCardService.changeCardStatus(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const changeVitaCardCurrency = useCallback(async (cardID, currency) => {
    try {
      const data = {
        card_id: cardID,
        currency_iso_code: currency
      };
      const response = await vitaCardService.changeVitaCardCurrency(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers, currency]);

  const tokenizationAddGPay = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        device_id: await getStableHardwareId(),
        wallet_account_id: await getActiveWalletID(),
      };

      const response = await vitaCardService.tokenizationAddGPay(headers, data);
      const {
        name,
        address,
        administrative_area: administrativeArea,
        country_code: countryCode,
        phone_number: phoneNumber,
        postal_code: postalCode,
        locality,
        last_digits,
        opc,
      } = response || {};

      const provisionResolve = await requestPushProvision(opc, name, last_digits, {
        name,
        address,
        locality,
        administrativeArea,
        countryCode,
        postalCode,
        phoneNumber,
      });

      return Promise.resolve(provisionResolve);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const tokenizationAddApplePay = useCallback(async (cardID) => {
    try {
      const data = {
        card_id: cardID,
        nonce: '',
        nonce_signature: '',
        certificates: '',
      };
      const response = await vitaCardService.tokenizationAddApplyPay(headers, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [headers]);

  const isAndroidTokenized = useCallback(async (identifier = '') => {
    try {
      let tokenized = await isTokenized(identifier);

      if (tokenized) {
        const tokens = await listTokens();
        tokenized = tokens.find(t => {
          return [t.fPanLastFour, t.dPanLastFour].includes(identifier)
        })?.tokenState.toString() === TOKEN_STATE_ACTIVE.toString() || false;
      }

      return Promise.resolve(tokenized);
    } catch (error) {
      return Promise.reject(error.message);
    }
  }, []);

  const changeCurrency = (sendCurrency) => {
    setCurrency(sendCurrency.toLowerCase());
  };

  return ({
    currency,
    currenciesAvailable,
    changeCurrency,
    cardCreationCost,
    cardCreationCostLabel,
    errors,
    currencyConfig,
    showCardInformation,
    freezeCard,
    unfreezeCard,
    deleteCard,
    changeVitaCardCurrency,
    tokenizationAddGPay,
    tokenizationAddApplePay,
    isAndroidTokenized,
  });
};

export default useVitaCard;