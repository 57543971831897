import React, {createContext, useCallback, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {SETTINGS_DEFAULT_VALUE, SETTINGS_KEY} from './state';

const settings = (useStorage) => {
  const SettingsContext = createContext({
    settings: SETTINGS_DEFAULT_VALUE,
    updateSettings: () => {},
    settingsIsReady: false,
  });
  const {Provider} = SettingsContext;

  const SettingsProvider = (props) => {
    const [settings, updateSettings, hydrated] = useStorage(
      SETTINGS_KEY,
      SETTINGS_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        settings,
        updateSettings,
        settingsIsReady: hydrated,
      }),
      [settings, updateSettings, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  SettingsProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useSettings = () => {
    const context = useContext(SettingsContext);

    if (!context) {
      throw new Error('useSettings must be used within a SettingsProvider');
    }

    const {settings, updateSettings: update, ...rest} = context;

    const updateSettings = useCallback(
      (newValue) => {
        update({
          ...settings,
          ...newValue,
        });
      },
      [settings, update],
    );

    /**
     ** PUSH NOTIFICATIONS
     */
    const addPushNotification = (pushNotification) => {
      /*
               Promocion por imagen
               {
               "collapseKey": "com.vita_wallet",
               "data": {
               "promotion_image": "https://firebasestorage.googleapis.com/v0/b/vita-wallet-api-qa-2.appspot.com/o/FCMImages%2Fpromo.png?alt=media&token=af93af43-9a91-43be-b7d3-ce20d69e1bd8"
               },
               "from": "1080238478021",
               "messageId": "0:1601300501550451%6fa267cb6fa267cb",
               "notification": {
               "android": {
               "imageUrl": "https://firebasestorage.googleapis.com/v0/b/vita-wallet-api-qa-2.appspot.com/o/FCMImages%2Fpromo.png?alt=media&token=af93af43-9a91-43be-b7d3-ce20d69e1bd8"
               },
               "body": "texto de notificacion",
                "title": "Titulo"
                },
                "sentTime": 1601300501374,
                "ttl": 2419200
                }
             */
      /*
                Promocion por texto
                {
                "collapseKey": "com.vita_wallet",
                "data": {
                "about_link": "https://vitawallet.io/",
                "message": "$2 USD por cada operación de $50 USD",
                "validation_date": "Válido hasta el 14 de Marzo"
                },
                "from": "1080238478021",
                 "messageId": "0:1601301003702952%6fa267cb6fa267cb",
                 "notification": {
                 "android": {}, "body": "texto de la notificacion", "title": "Titulo"
                 },
                 "sentTime": 1601301003484,
                 "ttl": 2419200
                 }
             */

      /*
            Transaccion
remoteMessage:  {
  "collapseKey": "com.vita_wallet",
      "data": {
    "object_data": "{
    \"amount\":\"0.001\",
    \"total\":\"0.001\",
    \"sender_remaining_balance\":\"0.00575315\",
    \"recipient_email\":\"josecaceres.oreul@gmail.com\",
    \"recipient_remaining_balance\":\"0.00425403\",
    \"currency\":\"btc\",
    \"id\":5274,
    \"recipient_address\":\"2MxVnHA2dH7Z9sb68wKDgyeSG8sz4aEMZrh\",
    \"category\":\"sent\",
    \"sender_email\":\"ghabriel@vitawallet.io\"}",
        "object_type": "transaction"
  }, "from": "1080238478021", "messageId": "0:1601642710676971%6fa267cb6fa267cb", "notification": {"android": {}, "body": "Has recibido BTC 0.001", "title": "Fondos recibidos"}, "sentTime": 1601642710608, "ttl": 2419200}
[Fri Oct 02 2020 07:45:20.625]  LOG      remoteMessage:  {"collapseKey": "com.vita_wallet", "data": {"object_data": "{\"amount\":\"0.001\",\"total\":\"0.001\",\"sender_remaining_balance\":\"0.00575315\",\"recipient_email\":\"josecaceres.oreul@gmail.com\",\"recipient_remaining_balance\":\"0.00425403\",\"currency\":\"btc\",\"id\":5274,\"recipient_address\":\"2MxVnHA2dH7Z9sb68wKDgyeSG8sz4aEMZrh\",\"category\":\"sent\",\"sender_email\":\"ghabriel@vitawallet.io\"}", "object_type": "transaction"}, "from": "1080238478021", "messageId": "0:1601642710677528%6fa267cb6fa267cb", "notification": {"android": {}, "body": "Has recibido BTC 0.001", "title": "Fondos recibidos"}, "sentTime": 1601642710608, "ttl": 2419200}
*/

      if (pushNotification.data) {
        const data = pushNotification.data.object_data
          ? JSON.parse(pushNotification.data.object_data)
          : pushNotification.data;

        const notification = {
          ...data,
          notification_type: pushNotification.data.object_type || 'promotion',
          sentTime: pushNotification.sentTime,
        };

        updateSettings({
          pushNotifications: [
            {...notification, isRead: false},
            ...settings.pushNotifications,
          ],
        });
      }
    };

    const isNewNotifications = useCallback(() => {
      const {pushNotifications} = settings;
      let cont = 0;

      pushNotifications.forEach((notification) => {
        if (!notification.isRead) {
          cont++;
        }
      });

      return cont > 0;
    }, [settings]);

    const showNotifications = useCallback(() => {
      const {pushNotifications} = settings;
      const allNotification = [];
      pushNotifications.map((notification) => {
        const {sentTime} = notification;
        notification.isRead = true;
        let isValid = true;

        if (moment().diff(moment.unix(sentTime), 'days') > 5) {
          isValid = false;
        }

        if (isValid) {
          allNotification.push(notification);
        }

        return notification;
      });

      updateSettings({
        pushNotifications: [...allNotification],
      });
    }, [settings, updateSettings]);
    /**
     ** PUSH NOTIFICATIONS
     */

    const clean = () => {
      update(SETTINGS_DEFAULT_VALUE);
    };

    return {
      ...rest,
      settings,
      addPushNotification,
      isNewNotifications,
      showNotifications,
      clean,
    };
  };

  return {
    SettingsProvider,
    useSettings,
  };
};

export default settings;
