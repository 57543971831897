import { useCallback, useEffect } from 'react';
import { MOBILE_VERSION } from 'alejandra/config/env';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useStorageCreator from '../hooks/useStorageCreator';
import useCleaner from '../hooks/useCleaner';
import useWindow from 'wallet/hooks/useWindow';

const VERSION_MOMENT = 'X-VERSION-MOBILE';
const MOBILE_VERSION_DEFAULT = '0.0.0';
let _isVersionMoment = false;

const useVersionMoment = () => {
  const useStorage = useStorageCreator(AsyncStorage);
  const { cleanData } = useCleaner();
  const { isWeb } = useWindow();
  const [versionMoment, updateVersionMoment, hydrated, getVersionMoment] = useStorage(VERSION_MOMENT);

  useEffect(() => {
    return () => {
      _isVersionMoment = false;
    };
  }, []);

  const checkingVersion = useCallback(async () => {
    if (!isWeb) {
      if (!_isVersionMoment) {
        _isVersionMoment = true;

        const dataVersion = await getVersionMoment();

        if (!dataVersion) { updateVersion() }
        else {
          if (dataVersion.MOBILE_VERSION !== MOBILE_VERSION) {
            try {
              await updateVersion();
              await cleanData();
            } catch { }
          }
        }
      }
    }
  }, []);

  const updateVersion = async () => {
    try {
      await updateVersionMoment({ MOBILE_VERSION });
    } catch { }
  };

  const clearVersionMoment = async () => {
    try {
      await updateVersionMoment({ MOBILE_VERSION_DEFAULT });
    } catch { }
  };

  return {
    versionMoment,
    hydrated,
    checkingVersion,
    clearVersionMoment,
  };
};

export default useVersionMoment;
