import { useReducer, useCallback, useEffect, useState, useMemo } from 'react';

import useAmounts from './useAmounts';
import config from '../config';
import useResidenceConfig from './useResidenceConfig';
import useTransferPrices from './useTransferPrices';
import usePrices from './usePrices';
import useServices from './useServices';
import usePrevious from './usePrevious';
import {
  formatMoney,
  isValidEmail,
  isValidBtcWallet,
  isValidUsdtAddress,
  isValidUsdcAddress,
  qrResolveFromVita,
  waitingAction,
  USD_RATE_ROUTES,
} from '../utils';

import { removeLeadingZerosBtc, isCryptoCurrency } from 'wallet/util';

const minimumAmountCurrency = (currency, minBtc = 0.00000001, minFiat = 1.00) => {
  currency = currency.toUpperCase();
  return currency === 'BTC'
    ? minBtc
    : isCryptoCurrency(currency)
      ? 1.00
      : minFiat;
};

const isValidDestinatary = (currency, destinatary) => {
  const validation = (isValidBtcWallet(destinatary) || isValidEmail(destinatary) || isValidUsdtAddress(destinatary));
  return isCryptoCurrency(currency) ? validation : isValidEmail(destinatary);
};

const isValidateForm = ({
  amount,
  sendCurrency,
  balances,
  destinatary,
  description,
  error,
  minimumAmount,
  isShowNetworks,
  network,
  commission,
}) => {
  const v1 = amount >= minimumAmount;
  const v2 = amount <= balances[sendCurrency.toLowerCase()];
  const v3 = isValidDestinatary(sendCurrency.toUpperCase(), destinatary.trim());
  const v4 = description && description.trim() !== '' && description.length <= 150;
  const v5 = !isShowNetworks || (isShowNetworks && network);
  const v6 = (amount - commission) > 0
  const v7 = !error;

  return (v1 && v2 && v3 && v4 && v5 && v6 && v7);
};

const AMOUNT_CHANGE = 'useCryptoSent/AMOUNT_CHANGE';
const CURRENCY_CHANGE = 'useCryptoSent/CURRENCY_CHANGE';
const DESCRIPTION_CHANGE = 'useCryptoSent/DESCRIPTION_CHANGE';
const DESTINATARY_CHANGE = 'useCryptoSent/DESTINATARY_CHANGE';
const ADD_ERROR = 'useCryptoSent/ADD_ERROR';
const ADD_MESSAGE = 'useCryptoSent/ADD_MESSAGE';
const USER_VALID = 'useCryptoSent/USER_VALID';
const DEFAULT_CURRENCY_AMOUNT_CHANGE = 'useCryptoSent/DEFAULT_CURRENCY_AMOUNT_CHANGE';
const RESET_FORM = 'useCryptoSent/RESET_FORM';
const CALCULATE_FIXED_COSTS = 'useCryptoSent/CALCULATE_FIXED_COSTS';
const PRICES_UPDATED = 'useCryptoSent/PRICES_UPDATED';
const CONFIRMATION = 'useCryptoSent/CONFIRMATION';
const SELECT_NETWORK = 'useCryptoSent/SELECT_NETWORK';
const ON_VALIDATE_FORM = 'useCryptoSent/ON_VALIDATE_FORM';

const reducer = (state, action) => {
  const { countryByCurrencyCode, decimalAmounts } = state;
  switch (action.type) {
    case AMOUNT_CHANGE: {
      const {
        sendCurrency,
        description,
        destinatary,
        error,
        message,
        is_allowed_transaction,
        minimumAmount,
        isShowNetworks,
        network,
        default_currency,
      } = state;
      const { amount, balances, prices } = action.payload;
      const commission = (isShowNetworks && network)
        ? (decimalAmounts(network.withdraw_fee, sendCurrency) * 100 + prices[default_currency][`${sendCurrency.toLowerCase()}_fee_send_external`] * 100) / 100
        : 0;
      const { currency_decimals } = countryByCurrencyCode(sendCurrency);
      const fixedFeeRoute = prices[default_currency][`${sendCurrency.toLowerCase()}_fee_send_external`];

      return {
        ...state,
        amount,
        amountLabel: formatMoney(amount, sendCurrency, currency_decimals),
        commission,
        commissionLabel: removeLeadingZerosBtc(formatMoney(commission, sendCurrency, currency_decimals)),
        fixedFeeRoute,
        defaultCurrencyAmount: amount * prices[sendCurrency.toLowerCase()][`${default_currency}_sell`],
        isValid: isValidateForm({
          amount,
          sendCurrency,
          balances,
          destinatary,
          description,
          error,
          minimumAmount,
          isShowNetworks,
          network,
          commission,
        }) && is_allowed_transaction,
      };
    }
    case DEFAULT_CURRENCY_AMOUNT_CHANGE: {
      const {
        sendCurrency,
        description,
        destinatary,
        error,
        is_allowed_transaction,
        minimumAmount,
        isShowNetworks,
        network,
        default_currency,
      } = state;
      const { defaultCurrencyAmount, balances, prices } = action.payload;
      const amount = defaultCurrencyAmount / prices[sendCurrency.toLowerCase()][`${default_currency}_sell`];
      const commission = (isShowNetworks && network)
        ? (decimalAmounts(network.withdraw_fee, sendCurrency) * 100 + prices[default_currency][`${sendCurrency.toLowerCase()}_fee_send_external`] * 100) / 100
        : 0;
      const { currency_decimals } = countryByCurrencyCode(sendCurrency);
      const fixedFeeRoute = prices[default_currency][`${sendCurrency.toLowerCase()}_fee_send_external`];

      return {
        ...state,
        amount,
        fixedFeeRoute,
        defaultCurrencyAmount,
        amountLabel: formatMoney(amount, sendCurrency, currency_decimals),
        commission,
        commissionLabel: removeLeadingZerosBtc(formatMoney(commission, sendCurrency, 8)),
        isValid: isValidateForm({
          amount,
          sendCurrency,
          balances,
          destinatary,
          description,
          error,
          minimumAmount,
          isShowNetworks,
          network,
          commission,
        }) && is_allowed_transaction,
      };
    }
    case CURRENCY_CHANGE: {
      const {
        prices,
        default_currency
      } = state;
      const { sendCurrency } = action.payload;
      const fixedFeeRoute = prices[default_currency][`${sendCurrency.toLowerCase()}_fee_send_external`];

      return {
        ...state,
        fixedFeeRoute,
        isShowNetworks: false,
        sendCurrency,
        sendCurrencyDecimals: countryByCurrencyCode(sendCurrency).currency_decimals,
        amount: 0,
        defaultCurrencyAmount: 0,
        amountLabel: formatMoney(0, sendCurrency),
        commission: 0,
        commissionLabel: formatMoney(0, sendCurrency),
        minimumAmount: minimumAmountCurrency(sendCurrency, undefined, state.min_amount_sents),
        isValid: false,
        destinatary: '',
        email_wallet: '',
        error: '',
        message: '',
        network: null,
        networkLabel: 'Seleccione red de envio',
      };
    }
    case DESCRIPTION_CHANGE: {
      const {
        sendCurrency,
        amount,
        destinatary,
        error,
        is_allowed_transaction,
        minimumAmount,
        isShowNetworks,
        network,
        commission
      } = state;

      const { description, balances } = action.payload;
      return {
        ...state,
        description,
        isValid: isValidateForm({
          amount,
          sendCurrency,
          balances,
          destinatary,
          description,
          error,
          minimumAmount,
          isShowNetworks,
          network,
          commission,
        }) && is_allowed_transaction,
      };
    }
    case DESTINATARY_CHANGE: {
      const { /* sendCurrency, amount, description, is_allowed_transaction, minimumAmount, */ isShowNetworks, network } = state;
      let { error } = state;
      const { destinatary, email_wallet/* , balances */ } = action.payload;

      if (destinatary !== '' && network) {
        if (!(new RegExp(network.address_regex).test(destinatary)) && isShowNetworks)
          error = 'Dirección no soportada por red.'
        else
          error = ''
      }

      return {
        ...state,
        destinatary,
        email_wallet,
        error,
        message: '',
        isValid: false,
        // isValid: isValidateForm(
        //   amount,
        //   sendCurrency,
        //   balances,
        //   destinatary,
        //   description,
        //   error,
        //   minimumAmount,
        //   isShowNetworks,
        //   network,
        // ) && is_allowed_transaction,
      };
    }
    case ADD_ERROR: {
      const { error, isShowNetworks } = action.payload;

      return {
        ...state,
        error,
        isShowNetworks: isShowNetworks ? isShowNetworks : state.isShowNetworks,
        isValid: false,
      };
    }
    case ADD_MESSAGE: {
      const {
        sendCurrency,
        amount,
        description,
        destinatary,
        is_allowed_transaction,
        minimumAmount,
        network,
        commissionLabel,
        commission,
        receiveUserFiat,
      } = state;
      let { error } = state;
      const {
        message,
        balances,
        commissionLabel: newLabel,
        commission: newCommission,
        minimumAmount: minPayload,
        isShowNetworks = false,
        resetUser,
      } = action.payload;

      if (destinatary !== '' && network) {
        if (!(new RegExp(network.address_regex).test(destinatary)) && isShowNetworks)
          error = 'Dirección no soportada por red.';
        else
          error = '';
      }

      const calculateMinAmount = minPayload ? minPayload : minimumAmount;

      return {
        ...state,
        message,
        error,
        commission: newCommission ? newCommission : commission,
        commissionLabel: newLabel ? newLabel : commissionLabel,
        minimumAmount: calculateMinAmount,
        isShowNetworks,
        receiveUserFiat: resetUser ? null : receiveUserFiat,
        isValid: isValidateForm({
          amount,
          sendCurrency,
          balances,
          destinatary,
          description,
          error,
          minimumAmount: calculateMinAmount,
          isShowNetworks,
          network,
          commission: newCommission ? newCommission : commission,
        }) && is_allowed_transaction,
      };
    }
    case USER_VALID: {
      const {
        sendCurrency,
        amount,
        description,
        is_allowed_transaction,
        minimumAmount,
        network,
        commission,
      } = state;
      let { error, destinatary } = state;
      const { balances, receiveUserFiat, fixedCostSend, isCashout, receiveRate } = action.payload;
      const isShowNetworks = false;

      if (destinatary !== '' && network) {
        if (!(new RegExp(network.address_regex).test(destinatary)) && isShowNetworks)
          error = 'Dirección no soportada por red.';
        else
          error = '';
      } else {
        error = '';
      }

      if (destinatary !== '' && countryByCurrencyCode(sendCurrency).is_crypto && receiveUserFiat) {
        destinatary = receiveUserFiat.email;
      }

      return {
        ...state,
        receiveUserFiat,
        destinatary,
        error,
        message: '',
        isCashout,
        receiveRate: receiveRate || 1,
        fixedCostSend,
        isShowNetworks,
        usdRateRoute: [],
        isValid: isValidateForm({
          amount,
          sendCurrency,
          balances,
          destinatary,
          description,
          error,
          minimumAmount,
          isShowNetworks,
          network,
          commission,
        }) && is_allowed_transaction,
      };
    }
    case RESET_FORM: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CALCULATE_FIXED_COSTS: {
      const {
        costoComision,
        costoGMF,
        costoIVA,
        costoTransaccion,
        is_allowed_transaction,
      } = action.payload;

      return {
        ...state,
        is_allowed_transaction,
        calculatedFixedCost: {
          costoComision,
          costoGMF,
          costoIVA,
          costoTransaccion,
        }
      }
    }
    case PRICES_UPDATED: {
      const {
        sendCurrency,
        amount,
        transferPrices,
        fixedCostSend,
        receiveRate,
        receiveUserFiat,
        calculatedFixedCost,
        isoCodeUser,
      } = state;

      let amountReceive = amount;
      let fixedCostSendCalculated = 0;
      let receiveRateBase = 1;

      if (receiveUserFiat && receiveUserFiat.default_currency.toUpperCase() !== sendCurrency.toUpperCase()) {
        amountReceive = (amount * receiveRate) - fixedCostSend;
        const iso_code = countryByCurrencyCode(receiveUserFiat.default_currency).iso_code;
        const pricesConfig = transferPrices.fiat[iso_code.toLowerCase()];
        receiveRateBase = decimalAmounts(amountReceive, iso_code) / amount;

        fixedCostSendCalculated = pricesConfig[`${sendCurrency.toLowerCase()}_is_apply_fixed_cost_in_sents`]
          ? pricesConfig[`${sendCurrency.toLowerCase()}_fixed_cost_send`]
          : 0;
      } else {
        amountReceive = amountReceive - fixedCostSend;
        receiveRateBase = decimalAmounts(amountReceive, isoCodeUser) / amount;
      }

      return {
        ...state,
        ...action.payload,
        amountReceive,
        fixedCostSendCalculated,
        receiveRateBase,
        calculatedFixedCost: {
          ...calculatedFixedCost,
          costoTransaccion: fixedCostSendCalculated,
        }
      }
    }
    case CONFIRMATION: {
      const {
        status,
      } = action.payload;
      const {
        sendCurrency,
        amount,
        transferPrices,
        fixedCostSend,
        receiveRate,
        receiveUserFiat,
        calculatedFixedCost,
        isoCodeUser,
      } = state;
      const {
        default_currency,
      } = receiveUserFiat || {};

      let { usdRateRoute } = state;
      let amountReceive = amount;
      let fixedCostSendCalculated = 0;
      let receiveRateBase = 1;

      if (receiveUserFiat && default_currency.toUpperCase() !== sendCurrency.toUpperCase()) {
        amountReceive = (amount * receiveRate) - fixedCostSend;
        const {
          iso_code,
          currency_decimals,
        } = countryByCurrencyCode(default_currency);
        const pricesConfig = transferPrices.fiat[iso_code.toLowerCase()];
        receiveRateBase = decimalAmounts(amountReceive, iso_code) / amount;
        fixedCostSendCalculated = pricesConfig[`${sendCurrency.toLowerCase()}_is_apply_fixed_cost_in_sents`]
          ? pricesConfig[`${sendCurrency.toLowerCase()}_fixed_cost_send`]
          : 0;

        if (USD_RATE_ROUTES.includes(sendCurrency.toLowerCase()) || USD_RATE_ROUTES.includes(default_currency.toLowerCase())) {
          const us_price = transferPrices.fiat['us'][`${sendCurrency.toLowerCase()}_spot`];

          usdRateRoute = [
            {
              oneCurrencyToEqualsLabel: `1 USDC = `,
              rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
            },
            {
              oneCurrencyToEqualsLabel: `1 USDC = `,
              rateWithCurrencyNameLabel: `${formatMoney((receiveRate / us_price), default_currency, currency_decimals)} ${default_currency.toUpperCase()}`,
            },
          ];
        }
      } else {
        amountReceive = amountReceive - fixedCostSend;
        receiveRateBase = decimalAmounts(amountReceive, isoCodeUser) / amount;
      }

      return {
        ...state,
        usdRateRoute,
        isConfirmation: status,
        amountReceive,
        fixedCostSendCalculated,
        receiveRateBase,
        calculatedFixedCost: {
          ...calculatedFixedCost,
          costoTransaccion: fixedCostSendCalculated,
        }
      }
    }
    case SELECT_NETWORK: {
      const {
        amount,
        sendCurrency,
        destinatary,
        description,
        minimumAmount,
        isShowNetworks,
        is_allowed_transaction,
        default_currency,
      } = state;

      let {
        error,
      } = state;
      const {
        network,
        balances,
        prices,
      } = action.payload;

      const commission = (decimalAmounts(network.withdraw_fee, sendCurrency) * 100 + prices[default_currency][`${sendCurrency.toLowerCase()}_fee_send_external`] * 100) / 100;
      const fixedFeeRoute = prices[default_currency][`${sendCurrency.toLowerCase()}_fee_send_external`];

      if (destinatary !== '' && network) {
        if (!(new RegExp(network.address_regex).test(destinatary)) && isShowNetworks)
          error = 'Dirección no soportada por red.'
        else
          error = ''
      }

      return {
        ...state,
        network: network,
        networkLabel: network.name,
        minimumAmount: decimalAmounts(network.withdraw_min, sendCurrency),
        commission,
        commissionLabel: `${removeLeadingZerosBtc(formatMoney(commission, sendCurrency, countryByCurrencyCode(sendCurrency).currency_decimals),)}`,
        fixedFeeRoute,
        error,
        isValid: isValidateForm({
          amount,
          sendCurrency,
          balances,
          destinatary,
          description,
          error,
          minimumAmount,
          isShowNetworks,
          network,
          commission,
        }) && is_allowed_transaction,
      }
    }
    case ON_VALIDATE_FORM: {
      return {
        ...state,
        touched: {
          destinatary: true,
          description: true,
          sendCurrency: true,
        }
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const useCryptoSent = (scene) => {
  const { useUser, useRules } = config.getInstance().getConfiguration();
  const { user } = useUser();
  const {
    isBTC,
    currency_plural_name,
    isSentVitaCard,
    currency_decimals,
    iso_code,
    min_amount_sents,
  } = useResidenceConfig();
  const { countryByCurrencyCode, decimalAmounts } = useAmounts();
  let {
    default_currency,
    balances,
  } = user;
  default_currency = default_currency.toLowerCase();

  const { prices } = usePrices();
  const { prices: transferPrices, isReadyPrices: transferPricesIsReady } = useTransferPrices();
  const { findUserFromEmailOrWallet } = useServices();
  const [dest, setDest] = useState('');
  const { cryptoCurrencies } = useRules();

  const isSentScene = useMemo(() => (scene === 'Sent'), [scene])
  const currenciesAvailable = useMemo(() => {
    let currencies = [...cryptoCurrencies];

    if (isSentScene) currencies.unshift(default_currency);

    return currencies;
  }, [cryptoCurrencies, isSentScene, default_currency]);

  const initialState = useMemo(() => ({
    amount: 0,
    default_currency,
    isoCodeUser: iso_code,
    defaultCurrencyAmount: 0,
    defaultCurrencyDecimals: currency_decimals,
    amountLabel: '0',
    commission: 0,
    commissionLabel: '0',
    // sendCurrency: isBTC ? 'BTC' : default_currency.toUpperCase(),
    sendCurrency: !isSentScene ? 'BTC' : default_currency.toUpperCase(),
    // sendCurrencyDecimals: isBTC ? countryByCurrencyCode('BTC').currency_decimals : currency_decimals,
    sendCurrencyDecimals: !isSentScene ? countryByCurrencyCode('BTC').currency_decimals : currency_decimals,
    description: '',
    destinatary: '',
    isValid: false,
    minimumAmount: minimumAmountCurrency(!isSentScene ? 'BTC' : default_currency, undefined, min_amount_sents),
    message: '',
    error: '',
    email_wallet: '',
    isCashout: false,
    receiveRate: 1,
    receiveRateBase: 1,
    fixedCostSend: 0,
    fixedCostSendCalculated: 0,
    isVitaCard: false,
    receiveUserFiat: null,
    countryByCurrencyCode,
    decimalAmounts,
    calculatedFixedCost: {
      costoComision: 0,
      costoGMF: 0,
      costoIVA: 0,
      costoTransaccion: 0,
    },
    is_allowed_transaction: true,
    isConfirmation: false,
    min_amount_sents,
    isShowNetworks: false,
    networkLabel: 'Seleccione red de envio',
    network: null,
    touched: {
      destinatary: false,
      description: false,
      sendCurrency: false,
    },
    usdRateRoute: [],
    fixedFeeRoute: 0,
  }), [
    iso_code,
    currency_decimals,
    isSentScene,
    default_currency,
    min_amount_sents,
    countryByCurrencyCode,
    decimalAmounts,
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const resetForm = useCallback(() => {
    dispatch({
      type: RESET_FORM,
      payload: initialState,
    });
  }, [initialState]);

  const changeDestinatary = useCallback(
    (destinatary, email_wallet = '', isReload = true) => {
      setDest(destinatary);
      dispatch({
        type: DESTINATARY_CHANGE,
        payload: { destinatary, email_wallet },
      });
    },
    [dispatch, setDest],
  );

  const changeAmount = useCallback(
    (amount) => {
      dispatch({
        type: AMOUNT_CHANGE,
        payload: { amount, balances, prices },
      });
    },
    [dispatch, balances, prices],
  );

  const prepareReceiveRate = useCallback((iso_country_receive) => {
    const prices_country = transferPrices.fiat[iso_country_receive.toLowerCase()];

    return prices_country[`${default_currency}_sell_send`];
  }, [transferPrices, default_currency]);

  const prepareFixedCostSend = useCallback((iso_country_receive) => {
    const prices_country = transferPrices.fiat[iso_country_receive.toLowerCase()];

    return prices_country[`${default_currency}_is_apply_fixed_cost_in_sents`]
      ? parseFloat(prices_country[`${default_currency}_fixed_cost_send`])
      : 0;
  }, [transferPrices, default_currency]);

  const prepareFixedCostVitaCard = useCallback((iso_country_receive) => {
    const prices_country = transferPrices.fiat[iso_country_receive.toLowerCase()];
    return prices_country[`${default_currency}_is_apply_fixed_cost_in_vita_card`]
      ? parseFloat(prices_country[`${default_currency}_fixed_cost_vita_card`])
      : 0;
  }, [transferPrices, default_currency])

  const fetchApiVerification = useCallback(() => {
    let isFindUser = true;

    const sendCurrencyConfig = countryByCurrencyCode(state.sendCurrency);

    if (isFindUser) {
      if (
        isValidEmail(dest) &&
        state.sendCurrency.toUpperCase() === default_currency.toUpperCase()
      ) {
        (async () => {
          try {
            const receiveUser = await findUserFromEmailOrWallet(
              dest.toLowerCase().trim(),
              state.isVitaCard,
            );
            const {
              data: {
                attributes: {
                  email,
                  profile_verification,
                  is_sent_receive,
                  default_currency:
                  receiveCurrency,
                  status: receiveStatus,
                  iso_code_country: isoCodeReceive,
                },
              },
            } = receiveUser;

            if (['accepted', 'profile_update_alerted'].includes(user.profile_verification)) {
              if (user.email === email) {
                dispatch({
                  type: ADD_ERROR,
                  payload: {
                    error: 'No puede enviar dinero a usted mismo',
                  },
                });
              } else {
                if (receiveStatus !== 'active') {
                  dispatch({
                    type: ADD_ERROR,
                    payload: {
                      error: `El usuario con el correo ${dest}, no está activo`,
                    },
                  });
                } else if (profile_verification !== 'accepted') {
                  dispatch({
                    type: ADD_ERROR,
                    payload: {
                      error: `El usuario con el correo ${dest}, no ha verificado su cuenta`,
                    },
                  });
                } else {
                  if (default_currency.toUpperCase() !== receiveCurrency.toUpperCase()) {
                    if (!is_sent_receive) {
                      dispatch({
                        type: ADD_ERROR,
                        payload: {
                          error: `El usuario con el correo ${dest
                            }, no puede recibir ${currency_plural_name}`,
                        },
                      });
                    } else if (is_sent_receive) {
                      dispatch({
                        type: ADD_ERROR,
                        payload: {
                          error: `El usuario con el correo ${state.destinatary
                            }, no ha finalizado su proceso de verificación.`,
                        },
                      });
                    } else {
                      if (!user.is_sent_available) {
                        dispatch({
                          type: ADD_ERROR,
                          payload: {
                            error: `No estas habilitado para enviar dinero a ${dest}`,
                          },
                        });
                      } else {
                        dispatch({
                          type: USER_VALID,
                          payload: {
                            balances,
                            receiveUserFiat: {
                              ...receiveUser.data.attributes,
                            },
                            isCashout: default_currency !== receiveUser.data.attributes.default_currency,
                            receiveRate: prepareReceiveRate(isoCodeReceive),
                            fixedCostSend: state.isVitaCard ? prepareFixedCostVitaCard(isoCodeReceive) : prepareFixedCostSend(isoCodeReceive),
                          },
                        });
                        dispatch({
                          type: ADD_MESSAGE,
                          payload: {
                            message: `Comisión: 0 ${default_currency.toUpperCase()}`,
                            balances,
                          },
                        });
                      }
                    }
                  } else {
                    dispatch({
                      type: USER_VALID,
                      payload: {
                        balances,
                        receiveUserFiat: {
                          ...receiveUser.data.attributes,
                        },
                        fixedCostSend: state.isVitaCard ? prepareFixedCostVitaCard(isoCodeReceive) : prepareFixedCostSend(isoCodeReceive),
                      },
                    });
                    dispatch({
                      type: ADD_MESSAGE,
                      payload: {
                        message: `Comisión: 0 ${default_currency.toUpperCase()}`,
                        balances,
                      },
                    });
                  }
                }
              }
            } else {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: 'Verifica tu cuenta para probar esta función.',
                },
              });
            }
          } catch (error) {
            if (error.user === 'vita card not found' || error.user === 'vita card inactive') {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: `El usuario no posee una Vita Card activa.`,
                },
              });
            } else {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: `El usuario no existe en Vita Wallet.`,
                },
              });
            }
          }
        })();
      }

      // Validate email to send crypto
      if (isValidEmail(dest) && sendCurrencyConfig.is_crypto) {
        (async () => {
          try {
            const receiveUser = await findUserFromEmailOrWallet(
              dest.toLowerCase().trim(),
              state.isVitaCard,
            );

            if (user.email === receiveUser.data.attributes.email) {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: `No puede enviar ${sendCurrencyConfig.currency_name} a usted mismo`,
                },
              });
            } else {
              if (
                receiveUser.data.attributes.active_cryptos &&
                receiveUser.data.attributes.active_cryptos[state.sendCurrency.toLowerCase()]
              ) {
                dispatch({
                  type: USER_VALID,
                  payload: {
                    balances,
                    receiveUserFiat: {
                      ...receiveUser.data.attributes,
                    },
                  }
                });
                dispatch({
                  type: ADD_MESSAGE,
                  payload: {
                    message: `Comisión: 0 ${state.sendCurrency.toUpperCase()}`,
                    balances,
                  },
                });
              } else {
                dispatch({
                  type: ADD_ERROR,
                  payload: {
                    error: `El usuario no ha activado su saldo ${state.sendCurrency.toUpperCase()}.`,
                  },
                });
              }
            }
          } catch (error) {
            dispatch({
              type: ADD_ERROR,
              payload: {
                error: `El correo no existe en Vita Wallet`,
              },
            });
          }
        })();
      }

      if (
        (
          isValidUsdtAddress(dest) ||
          isValidUsdcAddress(dest) ||
          isValidBtcWallet(dest)
        ) && sendCurrencyConfig.is_crypto
      ) {
        (async () => {
          try {
            const receiveUser = await findUserFromEmailOrWallet(
              dest,
              state.isVitaCard,
            );

            if (user.email === receiveUser.data.attributes.email) {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: `No puede enviar ${sendCurrencyConfig.currency_name} a usted mismo`,
                },
              });
            } else {
              dispatch({
                type: USER_VALID,
                payload: {
                  balances,
                  receiveUserFiat: {
                    ...receiveUser.data.attributes,
                  },
                }
              });
              dispatch({
                type: ADD_MESSAGE,
                payload: {
                  message: `${receiveUser.data.attributes.email}\n${receiveUser.data.attributes.btc_address}\n\nComisión: 0 ${state.sendCurrency.toUpperCase()}`,
                  balances,
                },
              });
            }
          } catch (error) {
            if (['USDT', 'USDC', 'BTC'].includes(state.sendCurrency.toUpperCase())) {
              dispatch({
                type: ADD_MESSAGE,
                payload: {
                  isShowNetworks: true,
                  message: '',
                  resetUser: true,
                  balances,
                },
              });
            }

            // if (state.sendCurrency.toUpperCase() === 'BTC') {
            //   dispatch({
            //     type: ADD_MESSAGE,
            //     payload: {
            //       message: `Wallet externa\nComisión: ${removeLeadingZerosBtc(
            //         formatMoney(prices[default_currency].btc_fee_send_external, 'BTC', 8),
            //       )} BTC\n(${formatMoney(
            //         prices[default_currency].btc_fee_send_external * prices['btc'][default_currency],
            //         default_currency,
            //         state.defaultCurrencyDecimals,
            //       )} ${default_currency.toUpperCase()})`,
            //       commission: prices[default_currency].btc_fee_send_external,
            //       commissionLabel: `${removeLeadingZerosBtc(formatMoney(prices[default_currency].btc_fee_send_external, 'BTC', 8),)}`,
            //       minimumAmount: minimumAmountCurrency('BTC', prices[default_currency].btc_min_total_send_external, min_amount_sents),
            //       resetUser: true,
            //       balances,
            //     },
            //   });
            // }
          }
        })();
      }
    }

    return () => {
      isFindUser = false;
    };
  }, [
    dest,
    state.sendCurrency,
    state.network,
    findUserFromEmailOrWallet,
    user.email,
    default_currency,
    balances,
    state.amount,
    changeDestinatary,
    changeAmount,
    prices,
    transferPrices,
  ]);

  useEffect(() => {
    return waitingAction(fetchApiVerification);
  }, [dest]);

  const previusCurrency = usePrevious(state.sendCurrency);
  const previusDest = usePrevious(dest);

  useEffect(() => {
    if (dest && previusCurrency && previusCurrency !== state.sendCurrency && dest === previusDest) {
      fetchApiVerification();
    }
  }, [state.sendCurrency]);

  useEffect(() => {
    changeAmount(state.amount);
  }, [changeAmount, prices]);

  useEffect(() => {
    let payload = {
      prices,
      transferPrices,
      default_currency,
    };

    if (state.receiveUserFiat) {
      payload.receiveRate = prepareReceiveRate(state.receiveUserFiat.iso_code_country);
    }

    dispatch({
      type: PRICES_UPDATED,
      payload,
    });
  }, [transferPrices, prices, state.receiveUserFiat, default_currency]);

  const changeDefaultCurrencyAmount = useCallback(
    (defaultCurrencyAmount) => {
      dispatch({
        type: DEFAULT_CURRENCY_AMOUNT_CHANGE,
        payload: { defaultCurrencyAmount, balances, prices },
      });
    },
    [dispatch, balances, prices],
  );

  const changeCurrency = useCallback(
    (sendCurrency) => {
      setDest('');
      dispatch({
        type: CURRENCY_CHANGE,
        payload: {
          sendCurrency: sendCurrency.toUpperCase()
        },
      });
    },
    [],
  );

  const changeDescription = useCallback(
    (description) => {
      dispatch({
        type: DESCRIPTION_CHANGE,
        payload: { description, balances, prices },
      });
    },
    [dispatch, balances, prices],
  );

  const onQrRead = async (qrCode) => {
    try {
      // TODO DAVID: Make usable currency from QR 
      const { address, /* currency */ } = await qrResolveFromVita(qrCode);
      if (address) {
        changeDestinatary(address);
      }
    } catch (error) {
      dispatch({
        type: ADD_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };

  const useMax = useCallback(() => {
    const { sendCurrency } = state;
    const amount = balances[sendCurrency.toLowerCase()];
    changeAmount(amount);
  }, [balances, changeAmount, state]);

  const setConfirmation = useCallback((status) => {
    dispatch({
      type: CONFIRMATION,
      payload: {
        status,
        default_currency,
      }
    });
  }, [
    default_currency,
  ]);

  const onSelectNetwork = useCallback((network) => {
    dispatch({
      type: SELECT_NETWORK,
      payload: {
        network,
        balances,
        prices,
      }
    });
  }, [balances, prices]);

  const onValidateForm = useCallback(() => {
    dispatch({
      type: ON_VALIDATE_FORM,
    });
  }, []);

  return {
    ...state,
    prices,
    onQrRead,
    changeAmount,
    changeCurrency,
    changeDescription,
    changeDestinatary,
    setConfirmation,
    changeDefaultCurrencyAmount,
    resetForm,
    useMax,
    isSentVitaCard,
    isSentScene: isSentScene,
    transferPricesIsReady,
    onSelectNetwork,
    currenciesAvailable,
    onValidateForm,
  };
};

export default useCryptoSent;
