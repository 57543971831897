import { ENDPOINTS } from 'dumdee/v2/config/constants';
import { apiCall } from 'dumdee/v2/middlewares';

const searchBeneficiary = (data, headers) => {
  return apiCall({
    ...ENDPOINTS.bankAccounts.searhBeneficiary,
    data,
    headers,
  });
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  searchBeneficiary,
};