import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import residenceConfigService from '../services/residenceConfig';
import { RESIDENCE_CONFIG_KEY, RESIDENCE_CONFIG_DEFAULT_VALUE } from './state';

const residenceConfig = (useStorage) => {
  const ResidenceConfigContext = createContext({
    residenceConfig: RESIDENCE_CONFIG_DEFAULT_VALUE,
    updateResidenceConfig: () => {},
    residenceConfigIsReady: false,
  });
  const { Provider } = ResidenceConfigContext;

  let _isFetch = false;

  const ResidenceConfigProvider = (props) => {

    const [residenceConfig, updateResidenceConfig, hydrated] = useStorage(
      RESIDENCE_CONFIG_KEY,
      RESIDENCE_CONFIG_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        residenceConfig,
        updateResidenceConfig,
        residenceConfigIsReady: hydrated
      }),
      [residenceConfig, updateResidenceConfig, hydrated]
    );
    return <Provider value={value} {...props} />;
  }

  ResidenceConfigProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useResidenceConfigContext = () => {
    const context = useContext(ResidenceConfigContext);

    if (!context) {
      throw new Error('useResidenceConfig must be used within a ResidenceConfigProvider');
    }

    const { residenceConfig, updateResidenceConfig: update, ...rest } = context;

    const updateResidenceConfig = useCallback(
      async (user, force = false) => {
        if (user) {
          if (!_isFetch && (moment().diff(residenceConfig.expiry, 'milliseconds') >= 30 || force)) {
            _isFetch = true;

            try {
              const response = await residenceConfigService.getResidenceConfig(user.headers);
              if (!response.data.attributes.recharge_providers?.length) {
                response.data.attributes.is_recharge = false;
              }
              let { attributes } = response.data;
              const { currency, local_currency, society, iso_code } = attributes;
              const expiry = attributes.valid_until;

              if (currency.toLowerCase() !== local_currency.toLowerCase() || (currency.toLowerCase() === 'usd' && iso_code !== 'US')) {
                attributes = {
                  ...attributes,
                  currency_decimals: 2,
                  currency_name: "Dólar estadounidense",
                  currency_plural_name: "Dolares estadounidenses",
                  flag_url: "https://api.vitawallet.io/mailers/flags/US.png",
                }
              }

              update({
                ...residenceConfig,
                ...attributes,
                society: society.trim() === "" ? null : society,
                expiry,
              });

            } catch { }

            _isFetch = false;
          }
        }
      }, [residenceConfig]);

    const cleanResidenceConfig = useCallback(() => {
      update({
        ...RESIDENCE_CONFIG_DEFAULT_VALUE,
        expiry: moment().toISOString(),
      });
    }, [update]);

    return {
      ...rest,
      residenceConfig,
      updateResidenceConfig,
      cleanResidenceConfig,
    }
  }

  return {
    ResidenceConfigProvider,
    useResidenceConfigContext
  };
};

export default residenceConfig;